<template>
	<v-dialog :value="value"
		@input="$emit('input', $event)"
		@keydown.esc="$emit('input', false)"
		max-width="800" scrollable>
		<v-card v-if="LanguageCodeSuffix === 'IT'">
			<v-card-title>
Donazioni alla fondazione THSN
			</v-card-title>
			<v-card-text>
				<div class="text-center">
				<a href="https://www.thehumansafetynet.org/it/" target="_blank">
					<v-img max-width="200" style="display: inline-block;"
						contain src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/donate-now-thsn.png">
					</v-img>
				</a>
				</div>
				<p>
				I progetti Aula 162 e ESIR sono coordinati dal Comitato di Milano della Croce Rossa. Da oltre 150 anni la Croce Rossa Italiana interviene per affrontare le situazioni di vulnerabilità delle comunità, contribuendo alla promozione della dignità umana. Tra le sfide globali della Strategia 2030, "Migrazione e identità" e "Valori, potere e inclusione" sono particolarmente rilevanti per i progetti di occupabilità e integrazione sociale dei rifugiati, realizzati dalla Croce Rossa di Milano.
				</p>
				<p>
				Il progetto ESIR mira a potenziare e sperimentare nuove modalità di sostegno all'inclusione sociale e lavorativa Oltre ai corsi professionali nei settori lavorativi tradizionali, come la logistica e la ristorazione, il progetto si concentra sull'inserimento lavorativo nel settore informatico, aggiornando e adattando il percorso di empowerment interno. A tutti i partecipanti viene offerto supporto personalizzato, anche psicologico, sempre disponibile e adattato alle esigenze individuali.  Il progetto si rivolge a persone nel territorio della città metropolitana di Milano.
				</p><p>
				Il progetto Aula 162, una collaborazione tra il comitato di Milano della Croce Rossa e Associazione NEXT, replica le attività del progetto ESIR in altre aree del territorio italiano, attivando partner aziendali in Emilia Romagna, Veneto, Piemonte, Lazio, Toscana per offrire corsi di formazione e servizi di inserimento lavorativo a titolari di protezione internazionale.
				</p><p>
						I due progetti si rivolgono a persone in possesso di regolare permesso di soggiorno per richiesta di asilo o già rifugiate e titolari di protezione (asilo politico, sussidiario o speciale). Adulti, uomini e donne, disoccupati o sottoccupati, tra i partecipanti sono inclusi i minori non accompagnati ormai adulti.
				</p><p>
						Complessivamente, sono più di 500 le persone formate grazie ai due progetti e più di 200 i posti di lavoro creati.
				</p>
				<p>	Ed ecco il sito: <a href="https://www.thehumansafetynet.org/" target="_blank">www.thehumansafetynet.org</a>
				</p>

			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('input', false)">{{ $t("common.close") }}</v-btn>
			</v-card-actions>
		</v-card>
		<v-card v-else>
				<v-card-title>
	Donations to the The Human Safety Net Foundation
				</v-card-title>
				<v-card-text>
				<p>
				As of 2019 The Human Safety Net has funded ESIR and Aula 162, two programs focused on training and job placement of refugees with a focus on job placement in different sectors, including the IT sector.
				</p><p>
				The ESIR program is mainly active in the Milan area, while the Aula 162 project is active throughout Italy.
				</p><p>
				The goal is to provide vocational training courses that meet the needs of local businesses and the aspirations of participants, accompanying them with language training, on soft skills, support for living and adapting to life in Italy.
				Impact from 2019: 450 program participants, 204 jobs created
				</p>
				<p>	Learn more on the website: <a href="https://www.thehumansafetynet.org/" target="_blank">www.thehumansafetynet.org</a>
				</p>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {}
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	props: {
		value: {
			type: Boolean
		}
	}
}
</script>
