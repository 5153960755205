<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<v-icon slot="divider" class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
		</v-breadcrumbs>

		<v-container fill-height >
			<v-row>
				<v-col cols="12">
					<div v-if="!LoadingDataCompleted">
						<v-skeleton-loader class="my-5" type="heading"></v-skeleton-loader>
						<v-skeleton-loader class="my-5" type="paragraph,paragraph,paragraph,paragraph"></v-skeleton-loader>
					</div>
					<div v-else>
						<h1 class="text-center  grey--text text--darken-2">{{ ChapterDetails["Name" + LanguageCodeSuffix] }}</h1>
						<div class="my-5 grey--text text--darken-3" v-if="LanguageCodeSuffix === 'IT'">
							<p v-for="part in ContentPartsIT" :key="part" class="text-body-1 ma-0 mb-1">
								{{ part }}
							</p>
						</div>
						<div class="my-5 grey--text text--darken-3" v-if="LanguageCodeSuffix === 'EN'">
							<p v-for="part in ContentPartsEN" :key="part" class="text-body-1 ma-0 mb-1">
								{{ part }}
							</p>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="pt-0">
					<PhotoGrid
							v-if="!LoadingDataCompleted"
							:Files="[{ blob: { Metadata: { AspectRatio: 1.2 } } },
							{ blob: { Metadata: { AspectRatio: 1.3 } } },
							{ blob: { Metadata: { AspectRatio: 0.8 } } },
							{ blob: { Metadata: { AspectRatio: 1.2 } } },
							{ blob: { Metadata: { AspectRatio: 1.2 } } }]"
							class="mt-5"
							:targetHeight="$vuetify.breakpoint.xs ? 250 : 400"
						>
							<template v-slot:cell="">
									<v-skeleton-loader type="image"></v-skeleton-loader>
								<div class="imageCaption my-3 text-center">
									<v-skeleton-loader  style="display: inline-block; width:200px" type="text"></v-skeleton-loader>
								</div>
							</template>
					</PhotoGrid>
					<PhotoGrid
						v-else
						:Files="ChapterDetails.ShopItems"
						class="mt-5"
						:targetHeight="$vuetify.breakpoint.xs ? 250 : 400"
					>
						<template v-slot:cell="{ file }">
							<router-link :to="`/${ProjectDetails.FriendlyUrl}/${ChapterDetails.ChapterId}/${file.ItemId}`">
								<AzurePreviewImage
								class="elevation-5"
									:file="file.blob"
								>
								</AzurePreviewImage>
							</router-link>
							<div class="imageCaption my-3 text-center">
								<MultiLineContent class="photoDescription font-italic text-body-2" :InputText="file['Content' + LanguageCodeSuffix]"></MultiLineContent>
							</div>
						</template>
					</PhotoGrid>
				</v-col>
			</v-row>
			<v-row v-if="LoadingDataCompleted">
				<v-col v-if="ChapterDetails.VimeoVideoUrl">
					<v-responsive aspect-ratio="1.5">
						<vueVimeoPlayer class="vimeo" :video-url="ChapterDetails.VimeoVideoUrl"></vueVimeoPlayer>
					</v-responsive>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import MultiLineContent from "@/components/Shared/Common/MultiLineContent.vue";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid";
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
	metaInfo() {
		return {
			title: this.ChapterDetails?.["Name" + this.LanguageCodeSuffix],
			meta: [{ name: "description", content: `Time To Change: ${this.ChapterDetails?.["Name" + this.LanguageCodeSuffix]}` }],
		};
	},
	name: "ChapterView",
	components: {
		AzurePreviewImage,
		MultiLineContent,
		vueVimeoPlayer,
		PhotoGrid
	},
	data() {
		return {
			ProjectDetails: null,
			LoadingDataCompleted: false,
			ChapterDetails: null,
			blobs: null,
			// chaptersList: null,
			breadcrumbsItems: [
				// {
				// 	text: "Home",
				// 	disabled: false,
				// 	exact: true,
				// 	to: "/",
				// }
			]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		ContentPartsIT() {
			if (!this.ChapterDetails) return null;
			return this.ChapterDetails.ContentIT?.split(/\r?\n/).filter((i) => i);
		},
		ContentPartsEN() {
			if (!this.ChapterDetails) return null;
			return this.ChapterDetails.ContentEN?.split(/\r?\n/).filter((i) => i);
		},
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		ChapterId() {
			return parseInt(this.$route.params.ChapterId);
		},

		// nextChapter() {
		// 	if (!this.chaptersList) return null;
		// 	const index = this.chaptersList.findIndex(c => c.ChapterId === this.ChapterId);
		// 	return (index + 1 === this.chaptersList.length) ? null : this.chaptersList[index + 1]
		// },
		// prevChapter() {
		// 	if (!this.chaptersList) return null;
		// 	const index = this.chaptersList.findIndex(c => c.ChapterId === this.ChapterId);
		// 	this.$log.debug(index);
		// 	return (index === 0) ? null : this.chaptersList[index - 1]
		// },
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		let projectId;
		if (this.$route.params.ProjectFriendlyUrl.toLowerCase() === "stefanoguindani") {
			projectId = 2;
			await this.SetProject(projectId);
			this.ProjectDetails = this.MainProject;
		}
		await this.LoadChapterDetails(this.ChapterId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		async LoadChapterDetails(chapterId) {
			try {
				this.ChapterDetails = await this.ProjectChaptersService.GetSelectedFields(chapterId, "*, ShopItems.*");
				this.blobs = await this.ProjectChaptersService.Get(this.ChapterId, "Blobs");

				this.ChapterDetails.ShopItems = this.ChapterDetails.ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);

				for (let i = 0; i < this.ChapterDetails.ShopItems.length; i++) {
					this.ChapterDetails.ShopItems[i].blob = this.blobs.find(b => this.ChapterDetails.ShopItems[i].ImagePath.endsWith(b.DisplayName));
				}

				this.breadcrumbsItems.push({
					text: this.ProjectDetails["Name" + this.LanguageCodeSuffix],
					disabled: false,
					exact: true,
					to: `/${this.ProjectDetails.FriendlyUrl}`,
				});
				this.breadcrumbsItems.push({
					text: this.ChapterDetails["Name" + this.LanguageCodeSuffix],
					disabled: true,
					exact: true,
					to: `/${this.ProjectDetails.FriendlyUrl}/${this.ChapterId}`,
				});

				this.LoadingDataCompleted = true;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			}
		}
	}
}
</script>
<style lang="scss">
.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.imageCaption{
	font-family: $font_Main;
	// font-weight: 400;
	height:60px;
	overflow: hidden;
	// font-style: italic;
	// text-align: center;
	height:80px;
	width:100%;
}
.navigationChapters{
	font-size:16px;
	font-weight: bold;
}
</style>
<i18n>
{
	"it": {
		"seeBackstage": "Vedi Backstage"
	},
	"en": {
		"seeBackstage": "See Backstage"
	}
}
</i18n>
