<template>
	<div >
		<v-container fluid fill-height>
			<v-row>
				<v-col cols="12" md="6" >
					<div class="text-center">
						<v-img src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/Logo/Time_To_Change_002.png"
							height="240" contain
							alt="Time to change by Stefano Guindani"></v-img>
					</div>
					<!-- <div class="text-center text-h5 mb-3">{{ $t("subtitle") }}</div> -->
				</v-col>
				<v-col cols="6" md="3" class="pt-8 pr-0">
					<h2 class="text-h6 text-center">{{ $t("headerTHSN") }}</h2>
					<div class="text-center">
						<a href="https://www.thehumansafetynet.org/it/" target="_blank">
							<v-img max-width="120" style="display: inline-block;"
								contain src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/donate-now-thsn.png">
							</v-img>
						</a>
						<br />
						<v-btn href="https://www.thehumansafetynet.org/it/" target="_blank" color="#b32625" dark>{{ $t("sgpshop.donate") }}</v-btn>
					</div>
				</v-col>
				<v-col cols="6" md="3" class="pt-10 pl-0">
					<v-img  :aspect-ratio="1.5" class="rounded elevation-5"
					src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/p1.jpg"></v-img>
				</v-col>
			</v-row>
		</v-container>
		<v-divider class="ma-8"></v-divider>
		<div class="pa-4" style="font-size: 1.1rem;" v-if="LanguageCodeSuffix === 'IT'">
			<p>
			Benvenuti sullo shop di SGP Stefano Guindani Photo. In questa sezione potrete acquistare le cartoline e le immagini del progetto <strong>BG4SDGs</strong> – Time to Change. I proventi delle vendite saranno destinati alla raccolta fondi di The Human Safety Net per i progetti ESIR e Aula 162 della Croce Rossa Italiana.
			</p><p>
			Da oltre 150 anni la Croce Rossa Italiana interviene per affrontare le situazioni di vulnerabilità delle comunità con mezzi e risorse proprie, diffondendo una cultura della non violenza e contribuendo alla promozione della dignità umana.
			</p><p>
			I progetti Aula 162 ed ESIR, che si concluderanno a Settembre 2025, mirano a potenziare e sperimentare nuove modalità di sostegno all'inclusione sociale e lavorativa Oltre ai corsi professionali nei settori lavorativi tradizionali, come la logistica e la ristorazione, i progetti si concentrano sull'occupazione nel settore informatico, aggiornando e adattando il percorso di empowerment interno. Un supporto personalizzato, anche psicologico, sempre disponibile.
			</p><p>
			Tra le sfide globali della Strategia 2030 della Croce Rossa Internazionale, "Migrazione e identità" e "Valori, potere e inclusione" sono particolarmente rilevanti per i progetti di occupabilità e integrazione sociale dei rifugiati, realizzati dalla Croce Rossa di Milano.
			</p>
		</div>
		<div class="pa-4" style="font-size: 1.1rem;" v-else>
			<p>
				Welcome to the SGP Stefano Guindani Photo shop. In this section you can buy postcards and
				images of the <strong>BG4SDGs</strong> – Time to Change project. Proceeds from sales will be used to raise
				funds for The Human Safety Net for the ESIR and Aula 162 projects of the Italian Red Cross.
			</p>
			<p>
				For over 150 years, the Italian Red Cross has been intervening to address situations of
				vulnerability in communities with its own means and resources, spreading a culture of non-
				violence and contributing to the promotion of human dignity.
			</p><p>
				The Aula 162 and ESIR projects, which will end in September 2025, aim to enhance and
				experiment with new ways of supporting social and work inclusion. In addition to the
				professional courses in traditional working sectors, such as logistics and catering, the projects
				focus on employment in the IT sector, updating and adapting the path of internal empowerment.
				Personalized support, including psychological support, is always available.
			</p><p>
				Among the global challenges of the 2030 Strategy of the International Red Cross, "Migration
				and identity" and "Values, power and inclusion" are particularly relevant for the projects of
				employability and social integration of refugees, carried out by the Red Cross of Milan.
			</p>
		</div>

		<v-container fluid>
			<v-row>
				<v-col cols="12" md="4" class="text-center" align-self="center">
						<v-btn x-large block color="grey darken-3" dark href="https://bg4sdgs.com/" target="_blank">
								{{ $t("seeAll") }}</v-btn>
						</v-col>
				<v-col cols="12" md="4" class="text-center" align-self="center">
					<v-btn x-large block color="grey darken-3" dark to="/TimeToChange/Gallery">
						{{ $t("gallery") }}</v-btn>
				</v-col>
				<v-col cols="12" md="4" class="text-center" align-self="center">
						<v-btn x-large block color="grey darken-3" dark to="/TimeToChange/Book">
							{{ $t("buyBook") }}</v-btn>
					</v-col>
			</v-row>
			<v-row>
				<v-col cols="6" md="4" v-for="image in homeImages" :key="image" :class="{'pa-5': $vuetify.breakpoint.mdAndUp}">
					<v-img aspect-ratio="1.5" class="rounded elevation-5" :src="'https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/Preview/'+image"></v-img>
				</v-col>
			</v-row>
		</v-container>

		<v-divider class="ma-8"></v-divider>
		<v-container fluid>
			<v-row>
				<v-col cols="7" md="12" order="1" order-md="1"><h2 class="sgpshop-h1">Stefano Guindani</h2></v-col>
				<v-col cols="5" order="2" md="4" order-md="3">
						<v-img src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/stefano%20guindani.jpg"></v-img>
				</v-col>
				<v-col cols="12" md="8" order="3" order-md="2">
					<div class="pa-4 text-body-1" v-if="LanguageCodeSuffix === 'IT'">
				<p>
				fotografo di respiro internazionale, nutre da sempre una grande passione per il linguaggio del reportage. Affermatosi come fotografo di moda e celebrity,
				Stefano approfondisce il suo interesse per il reportage prima in Cina poi ad Haiti. La povertà e la dignità del popolo di Haiti lo colpiscono profondamente,
				tanto che decide di mettere a disposizione il materiale scattato durante i suoi viaggi per la raccolta fondi destinati alle attività della Fondazione Francesca Rava
				N.P.H. Italia Onlus: con Electa pubblica il libro “Haiti through the eye of Stefano Guindani” e con le immagini realizza mostre fotografiche a New York e, con gli
				scatti dedicati a Saut d’Eau, a Palazzo Vecchio a Firenze, a Palazzo Isimbardi e in Triennale a Milano.
				</p>
				<p>
				Nel 2015, in occasione dei 60 anni dell’organizzazione internazionale N.P.H.- Nuestros Pequenos Hermanos, esce, edito da Skira, il volume “Do you know?”
				che racconta la dura realtà di alcuni paesi del centro e sud America. La prima copia del libro viene consegnata a Papa Francesco da Stefano durante l’udienza del
				2 dicembre 2015. Nel 2014 è coach e giudice di Scattastorie NX Generation, il primo talent show televisivo dedicato al mondo della fotografia andato in onda su
				Real Time, canale di Discovery. Nel 2015 firma i ritratti fotografici di “Ricette e ritratti d’autore”, format web e tv realizzato da Rai Cinema e Condé Nast,
				da cui sono nati in seguito un libro edito da Rai Eri e una mostra a Los Angeles. Nel dicembre 2015 viene chiamato da Samsung a interpretare Porta Nuova,
				il nuovo quartiere tecnologico di Milano. A seguito della pubblicazione di “Frames of Milan” nascono i volumi fotografici “Frames of Iceland” e
				“Frames of Seoul “che ne sono l’ideale prosecuzione. Dal 2016 è testimonial del prestigioso marchio Leica.
				</p>
				<p>
				A maggio 2016 esce il volume “Sguardi d’attore” che raccoglie i ritratti di oltre 350 attori del cinema italiano. A novembre 2017 inaugura a Milano una mostra
				fotografica dal titolo EY YOU!, una raccolta delle opere più suggestive di Haiti. La mostra viene “presentata alla città” con una spettacolare installazione in
				esterno: sulle vetrate di Microsoft House vengono applicate gigantografie di bambini sorridenti ritratti da Stefano. https://vimeo.com/manage/videos/251450457
				</p>
				<p>
				Nel 2018, in occasione del Salone del mobile Stefano espone le sue immagini presso l’Università La Statale nell’esposizione “My dream home” all’interno
				di una creativa installazione. Nel 2018 realizza alcune opere che raccontano alcuni tra i beni più iconici del FAI che vengono poi esposte per la prima volta
				all’interno della Torre Hadid a Milano. Nel 2019 viene scelto come unico rappresentante italiano membro della giuria internazionale di Huawei Next-Image presieduta
				da Steve McCurry. Nel 2020 è direttore creativo e uno dei fotografi del progetto editoriale di Lamborghini edito da Skirà “With Italy For Italy”. Nello stesso anno
				realizza un importante volume che racconta la città di “Genova” a cui segue una mostra diffusa per le vie principali della città. Nel 2021 esce il libro “Energie”
				per Axpo che racconta il mondo dello sport professionistico e dilettantistico.
				</p>
				<p>
				Nel 2022 espone la mostra di immagini del progetto “Mens Sana in Corpore sano”presso la Leica Galerie di Milano.
				</p>
				<p>
				Nel 2023, è inoltre testimonial per Porsche per il progetto “Dreamers On”. https://dreamerson.porscheitalia.com/stefano-guindani
				https://www.instagram.com/p/Cr2-38itCIy/?hl=it
				</p>
				<p>
				Stefano Guindani è titolare dell’agenzia video/fotografica SGP Stefano Guindani Photo. L’agenzia è composta da circa 30 persone di cui 12 fotografi e
				3 videomaker. Da anni Stefano, insieme al suo staff, ha collaborazioni continuative con importanti brand del lusso come Armani, Versace, Lamborghini,
				Maserati, Porsche, Valentino, Mille Miglia, Fendi, Alberta Ferretti, Luxottica, Pirelli, Tod’s, Moschino e tanti, tanti altri.
				</p>
					</div>
					<div class="pa-4 text-body-1" v-else>
						<p>
						An international photographer, he has always shown a deep passion for the language of reportage. After being recognised as fashion and celebrity photographer, Stefano explored its interest for reportage, first in China and then in Haiti. Deeply moved by the poverty and dignity of the Haitian population, he decided to use the pictures taken during his travels to raise funds for the initiatives of the Francesca Rava N.P.H. Italia Onlus Foundation: he published the book “Haiti through the eye of Stefano Guindani” (Electa) and organised several exhibitions in New York, in addition to those at Palazzo Vecchio in Florence and at Palazzo Isimbardi and the Triennale in Milan, with shots dedicated to Saut d’Eau.
						</p><p>
						In 2015, on the occasion of the 60th anniversary of the international organisation N.P.H. - Nuestros Pequenos Hermanos, he published the volume “Do you know?” (Skira), which depicts the harsh life in some countries in Central and South America. Stefano gave the first copy of this book to Pope Francis, during a papal audience on 2 December 2015. In 2014, Stefano participated as coach and judge in Scattastorie NX Generation, the first TV talent show dedicated to the world of photography, broadcast on Real Time, a Discovery channel. In 2015, he shot the portraits of “Ricette e ritratti d’autore”, a Web and TV format produced by Rai Cinema and Condé Nast, which was followed by a book, published by Rai Eri and an exhibition in Los Angeles. In December 2015, he was invited by Samsung to give his interpretation of Porta Nuova, Milan’s new technological district. The publication of “Frames of Milan” then led to the creation of two photographic volumes that were the ideal sequel: “Frames of Iceland” and “Frames of Seoul”. Since 2016, he has been a spokesperson for the prestigious Leica brand.
						</p><p>
						In May 2016, he published the book “Sguardi d’attore”: a collection of over 350 portraits of Italian movie actors. In November 2017, he inaugurated a photographic exhibition in Milan entitled EY YOU!, a collection of the most suggestive shots taken in Haiti. The exhibition was revealed to the city with a spectacular outdoor installation: a series of blow-up photos of smiling Haitian children portrayed by Stefano were affixed to the windows of the Microsoft House. https://vimeo.com/manage/videos/251450457
						</p><p>
						In 2018, on the occasion of the “Salone del mobile” trade fair, Stefano displayed its pictures at La Statale University, in the exhibit My dream home, within a creative installation. In 2018, he portrayed some of the most iconic landmarks of FAI (Italian Environmental Fund) and the photographs were displayed for the first time inside the Hadid Tower in Milan. In 2019, he was selected as the sole Italian representative in the international jury of Huawei Next-Image, chaired by Steve McCurry. In 2020, he was creative director and one of the photographers of the Lamborghini editorial project “With Italy For Italy”, published by Skirà. In the same year, he published an important volume on “Genoa”, followed by an exhibition that unfolded through the main streets of the city. In 2021, he published the book “Energie” for Axpo, about the world of professional and amateur sports.
						</p><p>
						In 2022, he organised an exhibition with images from the project “Mens Sana in Corpore sano” at the Milan Leica Galerie.
						</p><p>
						In 2023, he was a Porsche spokesperson within the project “Dreamers On”. https://dreamerson.porscheitalia.com/stefano-guindani
						https://www.instagram.com/p/Cr2-38itCIy/?hl=it
						</p><p>
						Stefano Guindani is the owner of the photo and video agency SGP Stefano Guindani Photo. The agency’s team is made up of about 30 people, of whom 12 photographers and 3 videomakers. Stefano and his team have long been partnering on an ongoing basis with major luxury brands including Armani, Versace, Lamborghini, Maserati, Porsche, Valentino, Mille Miglia, Fendi, Alberta Ferretti, Luxottica, Pirelli, Tod’s, Moschino and many, many others.
						</p>
					</div>
					<div class="pa-4 text-body-1">
						<p>
						SGP Stefano Guindani Photo: <br />
						www.sgpphotoagency.com <br />
						www.sgpitalia.com<br />
						<br />
						<a href="https://www.instagram.com/stefanoguindani/?hl=it">www.instagram.com/stefanoguindani</a>
						</p>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<v-divider class="ma-8"></v-divider>

		<HomeTHSN></HomeTHSN>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import HomeTHSN from "./StaticContent/HomeTHSN"
export default {
	name: "Home",
	components: {
		HomeTHSN
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			LoadingProject: false,
			ProjectDetails: null,
			homeImages: ["5/L1040177_R.jpg",
				"15/L1000480_R.JPG",
				"17/_SGZ1288_R.JPG",
				"13/_GUI6939_R.JPG",
				"4/L1030777_R.JPG",
				"1/SG1_6283_R.jpg"]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
	},
	async mounted() {
		const sustainabilityProjecId = 1;
		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

	}
}
</script>
<i18n>
{
	"it":{
		"pageTitle": "Stefano Guindani Shop - Acquista le foto del progetto Agenda ONU 2030",
		"description": "Scopri le foto di Stefano Guindani per il progetto Agenda ONU 2030: 17 obiettivi per lo sviluppo sostenibile. Puoi visualizzare e acquistare le foto.",
		"seeAll": "Scopri il progetto",
		"subtitle": "Agenda ONU 2030: 17 obiettivi per lo sviluppo sostenibile",
		"gallery": "Acquista le foto",
		"headerTHSN": "A sostegno di",
		"buyBook": "Acquista il libro"
	},
	"en":{
		"pageTitle": "Stefano Guindani Shop - Buy fine art prints of THE 17 GOALS Sustainable Development",
		"description": "Discover the photograhy of Stefano Guindani for the THE 17 GOALS Sustainable Development project. You can order fine prints online.",
		"subtitle": "THE 17 GOALS | Sustainable Development",
		"gallery": "Buy the images",
		"seeAll": "Explore the project",
		"headerTHSN": "Supporting",
		"buyBook": "Buy the book"
	}
}
</i18n>
