<template>
	<v-dialog :value="value"
		@input="$emit('input', $event)"
		@keydown.esc="$emit('input', false)"
		max-width="500" scrollable>
		<v-card>
			<v-card-title>
				{{ $t("title") }}
			</v-card-title>
			<v-card-text v-if="LanguageCodeSuffix === 'IT'">
				<p>
					Un NFF, acronimo di Non Fungible File, è un File univoco ed esclusivo in quanto cifrato e contenente l’attestazione
					di data certa. Filegrant certifica la proprietà di chi lo ha creato.
				</p><p>
					Il File NFF è protetto e immutabile, possono accedere al contenuto solo coloro che sono autorizzati a decifrarlo.
				</p><p>
					Chi acquista un NFF ottiene un certificato di proprietà di una delle copie del file decifrabile.
					Riceverà il file NFF in formato PDF protetto e potrà accedere al contenuto cifrato <strong>aprendo online</strong> il PDF
					<strong>tramite il viewer di Filegrant</strong>.
				</p><p>
					È possibile farlo facilmente cliccando sul PDF o caricandolo su <a href="https://app.filegrant.com" _target="_blank">app.filegrant.com</a> oppure aprendo il PDF protetto con una
					delle app Filegrant disponibili su Apple Store e Google Play.
				</p><p>
					Il download del file decifrato e la possibilità di salvarlo in locale per l'utilizzo offline <strong>non sono disponibili</strong>.
				</p>

			</v-card-text>
			<v-card-text v-else>
				<p>
				An NFF, an acronym for Non Fungible File, is a unique and exclusive file as it is encrypted and contains a timestamp certification. Filegrant certifies the ownership of the creator.
				</p><p>
				The NFF file is protected and immutable, allowing access to the content only to those authorized to decrypt it. Whoever purchases an NFF receives a certificate of ownership for one of the decryptable file copies.
				They will receive the NFF file in a protected PDF format and can access the encrypted content by opening the PDF online through the Filegrant viewer.
				</p><p>
				This can quickly be done by clicking on the PDF or uploading it to <a href="https://app.filegrant.com" _target="_blank">app.filegrant.com</a> or by opening the protected PDF using one of the Filegrant apps available on Apple Store and Google Play.
				</p><p>
				However, the download of the decrypted file and the ability to save it locally for offline use are unavailable.
				</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('input', false)">{{ $t("common.close") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {}
	},
	props: {
		value: {
			type: Boolean
		}
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	}
}
</script>
<i18n>
	{
		"it":{
"title": "Cos’è un NFF"
		},
		"en": {
"title": "What's an NFF"
		}
	}
</i18n>
