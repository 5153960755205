import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

import { captureRouterError } from "@/utilities/Sentry"

import Login from "@/views/Base/Login.vue"
import RecoverPassword from "@/views/Base/RecoverPassword.vue"
import ResetPassword from "@/views/Base/ResetPassword.vue"
import Error404 from "@/views/Base/Error404.vue"
import AccountRoutes from "@/views/Account/routes"
import ShopClients from "@/views/ShopClients/routes"

import TimeToChangeProjectView from "../views/Shop/TimeToChange/ProjectDetails.vue"
import ProjectView from "../views/Shop/Projects/ProjectDetails.vue"

import TimeToChangeChapterView from "../views/Shop/TimeToChange/ChapterView.vue"
import ChapterView from "../views/Shop/Projects/ChapterView.vue"

import TimeToChangeItemDetails from "../views/Shop/TimeToChange/ItemDetails.vue"
import ItemDetails from "../views/Shop/Projects/ItemDetails.vue"
import CompletePostcardOrder from "../views/Shop/TimeToChange/CompletePostcardOrder.vue"
import TimeToChangeProjectPhotos from "../views/Shop/TimeToChange/ProjectPhotos.vue"
import ProjectPhotos from "../views/Shop/Projects/ProjectPhotos.vue"
import Basket from "../views/Shop/Basket.vue"
import CheckoutRegister from "../views/Shop/CheckoutRegister.vue"

import NFFCheckoutRegister from "../views/Shop/TimeToChange/NFFCheckoutRegister.vue"

Vue.use(VueRouter)

const routes = [
	...AccountRoutes,
	...ShopClients,
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/TimeToChange",
		name: "ProjectView",
		component: TimeToChangeProjectView
	},
	{
		path: "/TimeToChange/Book",
		name: "Book",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Book.vue")
	},
	{
		path: "/TimeToChange/Gallery",
		name: "TimeToChangeProjectPhotos",
		component: TimeToChangeProjectPhotos
	},
	{
		path: "/TimeToChange/:ChapterId",
		name: "ChapterView",
		component: TimeToChangeChapterView
	},
	{
		path: "/TimeToChange/:ChapterId/:ItemId",
		name: "ItemDetails",
		component: TimeToChangeItemDetails
	},
	//

	//
	{
		path: "/TimeToChange/Postcard/:ItemId/Success",
		component: CompletePostcardOrder
	},
	{
		path: "/Basket",
		name: "Basket",
		component: Basket
	},
	{
		path: "/CheckoutRegister",
		name: "CheckoutRegister",
		component: CheckoutRegister
	},
	{
		path: "/NFFCheckoutRegister",
		name: "NFFCheckoutRegister",
		component: NFFCheckoutRegister
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/RecoverPassword",
		name: "recoverPassword",
		component: RecoverPassword
	},
	{
		path: "/ResetPassword",
		name: "resetPassword",
		component: ResetPassword
	},

	{
		path: "/Support",
		name: "Support",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Support.vue")
	},
	{
		path: "/Privacy",
		name: "Privacy",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Privacy.vue")
	},
	{
		path: "/terms-conditions",
		name: "Terms",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/TermsOfUse.vue")
	},

	{
		path: "/error/404",
		component: Error404
	},
	{
		path: "/:ProjectFriendlyUrl",
		name: "ProjectView",
		component: ProjectView
	},
	{
		path: "/:ProjectFriendlyUrl/Book",
		name: "Book",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Book.vue")
	},
	{
		path: "/:ProjectFriendlyUrl/Gallery",
		name: "ProjectPhotos",
		component: ProjectPhotos
	},
	{
		path: "/:ProjectFriendlyUrl/:ChapterId",
		name: "ChapterView",
		component: ChapterView
	},
	{
		path: "/:ProjectFriendlyUrl/:ChapterId/:ItemId",
		name: "ItemDetails",
		component: ItemDetails
	},
	{
		path: "*",
		redirect: to => {
			// the function receives the target route as the argument
			captureRouterError(to);
			return "/error/404";
		}
	}
]

const router = new VueRouter({
	mode: "history",
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
	base: process.env.BASE_URL,
	routes
})

export default router
