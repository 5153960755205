<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
				<v-icon slot="divider" class="secondary--text text--lighten-2"
					>fas fa-caret-right</v-icon
				>
			</v-breadcrumbs>
			<v-skeleton-loader v-if="LoadingData" type="article"> </v-skeleton-loader>
			<div v-else>
				<v-card color="success" class="text-center pa-5 mb-5">
					<h3 class="text-h5 white--text">
						<v-icon color="white">fas fa-check</v-icon> {{ $t("thankYouTitle") }}
					</h3>
					<p class="white--text">
						il file Jpg è stato inviato al tuo indirizzo email.
					</p>
					<div class="text-center">
						<a style="text-decoration:none;"
						:href="ItemDetails.ImagePreviewUrl" target="_blank">
							<v-icon  size="40" color="white">fas fa-download</v-icon>
							<span  class="white--text text-h5"> {{ $t("download") }} </span>
						</a>
					</div>
				</v-card>

			</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
export default {
	metaInfo() {
		return {
			title: this.$t("thankYouTitle")
		};
	},
	data() {
		return {
			ItemDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				// {
				// 	text: this.$t("sgpshop.shopClients"),
				// 	disabled: false,
				// 	exact: true,
				// 	to: "/ShopClients",
				// }
			],

			LoadingData: false,
		}
	},
	computed: {
		ItemId() {
			return this.$route.params.ItemId;
		},
	},
	created() {
		this.ShopItemsService = new CrudClient("Shop/Items");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: "Cartolina",
			disabled: true,
			exact: true,
			to: `/TimeToChange/Postcard/${this.ItemId}/Success`,
		});

		await this.LoadItemDetails();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadItemDetails() {
			try {
				this.LoadingItemDetails = true;
				this.ItemDetails = await this.ShopItemsService.Get(this.$route.params.ItemId);
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingItemDetails = false;
			}
		},
		OpenFile() {
		}
	}
}
</script>
<i18n>
	{
		"it":{
			"thankYouTitle": "Grazie per la tua Donazione!",
			"download": "Scarica il file"
		},
		"en":{
			"thankYouTitle": "Thank you for your donation!",
			"download": "Download the image"
		}
	}
</i18n>
