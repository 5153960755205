<template>
	<v-card class="mb-5">
		<v-card-title>
			{{ $t("title") }}

			<v-spacer></v-spacer>
			<v-btn text color="info" @click="showNffDialog = true">
				<v-icon left>fas fa-question-circle</v-icon>
					{{ $t("whatIsNFF") }}
				</v-btn>	<NffInfoDialog v-model="showNffDialog"> </NffInfoDialog>
		</v-card-title>
		<v-card-text>
			<div>
				{{ $t("productDescription1") }}

				<br />
				{{ $t("productDescription2") }}
				</div>

				<div class="text-subtitle-1 font-weight-bold">
					{{ $t("donationAmount") }}
				</div>
				<div>
					{{ $t("maxAvailability") }}
				</div>
			<!-- <div>
					{{ $t("donationInfo") }}
				<v-icon color="primary" @click="showInfoDialog = true">fas fa-info-circle</v-icon>
				<DonationInfoDialog  v-model="showInfoDialog"></DonationInfoDialog>
			</div> -->
			<AddEditBillingProfile
				v-model="showAddBillingProfile"
				v-if="isUserAuthenticated" @updated="OnBillingProfileUpdated"
				:hideCancelButton="true" :suppressSuccessMessage="true" :mode="1">
			</AddEditBillingProfile>

			<br/>
		</v-card-text>
		<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn large color="primary" @click="RedirectToDownload(ItemDetails)"
					>
					{{ $t("common.continue") }}</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import NffInfoDialog from "./NFFInfoDialog";
// import DonationInfoDialog from "./components/DonationInfoDialog.vue";

import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile"
import CrudClient from "@/services/CrudClient/";

export default {
	components: { NffInfoDialog, AddEditBillingProfile },
	data() {
		return {
			showAddBillingProfile: false,
			showNffDialog: false,
			showInfoDialog: false
		}
	},
	props: {
		ItemDetails: { type: Object }
	},
	computed: {
		...mapGetters([
			"isUserAuthenticated",
			"isUserInRole"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	created() {
		this.BillingProfileService = new CrudClient(
			"SGP/Client/BillingProfilesSelf"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async RedirectToDownload(item) {
			if (!this.isUserAuthenticated) {
				this.$router.push("/NFFCheckoutRegister?ItemId=" + item.ItemId);
				return;
			}

			// isUserInRole
			if (!this.isUserInRole("ShopClient")) {
				this.$router.push("/NFFCheckoutRegister?ItemId=" + item.ItemId);
				return;
			}

			const res = await this.BillingProfileService.GetPaged({
				limit: 0,
				fields: "*, ItalianCity.Name, Country.DescrizioneNazione",
				filter: "IsActive:true"
			});

			// if no billing Profile open dialog
			if (res.Data.length === 0) {
				this.showAddBillingProfile = true;
				return;
			}
			this.openInNewTab(item.NFFLink)
		},
		OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;

			// TODO: check delivery address

			this.openInNewTab(this.ItemDetails.NFFLink);
		},
		openInNewTab(url) {
			window.open(url, "_blank", "noreferrer");
		},
	}
}
</script>
<i18n>
	{
		"it":{
"title":"NFF + Stampa fine art 20x30cm",
"whatIsNFF":"Cosa è un NFF?",
"maxAvailability": "TIRATURA LIMITATA: 5 NFF disponibili",
"donationAmount": "Prezzo 450€",
"donationInfo": "Il ricavato al netto delle spese, verrà devoluto alla Fondazione The Human Safety Net per sostenere i progetti  Aula 162 e ESIR.",
"productDescription1": "Acquistando un NFF, riceverai un file a tiratura limitata criptato che certifica la tua univoca proprietà.",
"productDescription2": "Riceverai inoltre una stampa 20x30 dello stesso soggetto stampata in fine art su carta Giclée 100% cotone da 310 gsm"

		},
		"en": {
"title":"NFF + Fine Art Print 20x30cm",
	"whatIsNFF": "What's an NFF",
	"maxAvailability": "LIMITED AVAILBILITY: only 5 NFF available",
	"donationAmount": "Donation starting from 450€"
		}
	}
</i18n>
