<template>
	<div>
		<div class="mb-4 onBgHoverColor elevation-5 rounded pointer"
			v-for="version in ItemDetails.ShopItemVersions"
			:key="version.VersionId"
			:class="{
						'grey lighten-1': version.RemainingAvailability === 0 ,
						'yellow lighten-4' : version.VersionId === value
				}"
			@click="SelectItem(version)">
			<v-container fluid>
				<v-row dense>
					<v-col class="text-h6" cols="8">
						{{ version["Name" + LanguageCodeSuffix] }}
					</v-col>
					<v-col>
						<div class="text-right text-h6 font-weight-bold" cols="4">
							{{ version.Price | formatCurrencyAvoidDecimal }}
						</div>
					</v-col>
				</v-row>
				<v-row dense class="text-left" v-if="version.VersionId === value">
					<v-col cols="12">
						<!-- <div>
							{{ $t("printSize") }} {{ version.Height }}x{{ version.Width }} cm
						</div> -->
						<div v-if="frameOptionChosen">
							{{ $t("passpartoutSize", [version.PassepartoutWidth]) }}
						</div>
						<div v-if="frameOptionChosen">
							{{ $t("totalSize") }}  {{ version.Height + 2 * version.PassepartoutWidth }}x{{ version.Width + 2 * version.PassepartoutWidth }}cm
						</div>
						<div  v-if="version.MaxAvailability !== null" class="mt-2">
							{{ $t("maxAvailibility", [version.RemainingAvailability]) }}
						</div>
					</v-col>
				</v-row>
			</v-container>
			<!-- <v-card-title>
				{{ version["Name" + LanguageCodeSuffix] }}
				<v-spacer></v-spacer>
				<div class="text-subtitle-1 font-weight-bold ml-2">
						{{ version.Price | formatCurrencyAvoidDecimal }}
				</div>
			</v-card-title> -->
		</div>
	</div>
</template>
<script>
// import ItemDetailsDialog from "./ItemDetailsDialog";
export default {
	components: {},
	props: {
		ItemDetails: { type: Object, required: true },
		value: { type: Number },
		frameOptionChosen: { type: Boolean }
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	methods: {
		SelectItem(version) {
			if (version.RemainingAvailability > 0) {
				this.$emit("input", version.VersionId);
			}
		}
	}
}
</script>
<i18n>
	{
		"it":{
			"availableFormats": "Formati disponibili",
			"priceFrom": "Prezzo a partire da ",
			"requestContact": "Per qualsiasi richiesta di altro formato o finitura ci potete contattare alla mail",
			"maxAvailibility": "Solo {0} stampe disponibili",
			"printMaterial": "carta Giclée 100% cotone da 310 gsm",
			"printSize": "Dimensione stampa ",
			"totalSize": "Dimensione totale",
			"passpartoutSize": "Dimensione passepartout {0}cm per lato"
		},
		"en":{
			"availableFormats": "Available formats",
			"priceFrom": "Price starting from ",
			"requestContact": "For any request for another format or finish, you can contact us at",
			"maxAvailibility": "Only {0} prints available",
			"printMaterial": "310gsm 100% cotton Giclée paper",
			"printSize": "Image size ",
			"totalSize": "Total size",
			"passpartoutSize": "Passepartout {0}cm on each side"
		}
	}
</i18n>
