import { render, staticRenderFns } from "./ItemDetailsDialog.vue?vue&type=template&id=560a9091&scoped=true"
import script from "./ItemDetailsDialog.vue?vue&type=script&lang=js"
export * from "./ItemDetailsDialog.vue?vue&type=script&lang=js"
import style0 from "./ItemDetailsDialog.vue?vue&type=style&index=0&id=560a9091&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560a9091",
  null
  
)

/* custom blocks */
import block0 from "./ItemDetailsDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports