<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<v-icon slot="divider" class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
		</v-breadcrumbs>
		<div v-if="LoadingProject">
			<v-skeleton-loader type="heading" class="mx-auto mb-5"></v-skeleton-loader>
			<v-skeleton-loader type="paragraph, paragraph"></v-skeleton-loader>
		</div>
		<div v-if="ProjectDetails">
				<h1 class="sgpshop-h1 text-uppercase">{{ ProjectDetails["Name" + LanguageCodeSuffix] }}</h1>
				<div>
					{{ ProjectDetails["Content" + LanguageCodeSuffix] }}
				</div>
		</div>
		<v-container v-if="LoadingProject || LoadingChapters">
			<v-row dense >
				<v-col cols="12" sm="6" md="4" lg="3" v-for="i in 17" :key="i" class="ma-0 pa-0">
					<v-container fluid class="pa-2" >
						<v-row dense fill-height>
							<v-col cols="12" class="ma-0 pa-0">
								<v-skeleton-loader type="image"></v-skeleton-loader>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>

		<v-container>
			<v-row dense >
				<v-col cols="12" sm="6" md="4" lg="3" v-for="chapter in ProjectChapters" :key="chapter.ChapterId" class="ma-0 pa-0">
					<router-link :to="`/TimeToChange/${chapter.ChapterId}`">
					<v-container fluid class="pa-2" >
						<v-row dense fill-height>
							<v-col cols="4" class="ma-0 pa-0"
							:class="`chapterBG${chapter.ChapterId}${LanguageCodeSuffix === 'IT' ? 'IT' : ''}`">
								<v-img
									class="ma-2"
									:src="`https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/ChapterIcons${ LanguageCodeSuffix }/Chapter${chapter.ChapterId}.png`"></v-img>
							</v-col>
							<v-col cols="8" class="ma-0 pa-0">
								<v-img v-if="chapter.ShopItems.length > 0"
									aspect-ratio="1.3"
									:src="chapter.ShopItems[0].ImagePreviewUrl"></v-img>
								<v-responsive v-else aspect-ratio="1.3" class="grey lighten-2">
								</v-responsive>
							</v-col>
						</v-row>
					</v-container>
					</router-link>
				</v-col>
			</v-row>
		</v-container>

		</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "ProjectDetails",
	components: {
	},
	data() {
		return {
			LoadingChapters: false,
			ProjectChapters: null,
			LoadingProject: false,
			ProjectDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		this.$log.debug(this.$route.params);
		const sustainabilityProjecId = 1;

		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;

		await this.SetBreadcrumbs(sustainabilityProjecId);
		await this.LoadProjectChapters(sustainabilityProjecId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		async LoadProjectChapters(projectId) {
			try {
				this.LoadingChapters = true;
				const res = await this.ProjectChaptersService.GetPaged({
					limit: 0,
					fields: "ChapterId, ShopItems.ImagePreviewUrl, ShopItems.IsActive, ShopItems.SortOrder",
					orderBy: "SortOrder",
					urlPostFix: `Project/${projectId}`
				});

				this.ProjectChapters = res.Data;

				for (let i = 0; i < this.ProjectChapters.length; i++) {
					this.ProjectChapters[0].ShopItems = this.ProjectChapters[0].ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		},

		async SetBreadcrumbs() {
			this.breadcrumbsItems.push({
				text: "Time To Change",
				disabled: true,
				exact: true,
				to: "/TimeToChange",
			});
		},

	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Time To Change: il progetto e i 17 obiettivi",
			"description": "Time To Change: il progetto e i 17 obiettivi per sviluppo sostenibile"
		},
		"en":{
			"pageTitle": "Time To Change: the 17 goals",
			"description": "Time To Change: the 17 goals for Sustainable Development."
		}
	}
</i18n>
