<template>
	<div>
		<v-card class="mb-5" v-for="version in ItemDetails.ShopItemVersions" :key="version.VersionId">
			<v-card-title>
				{{ version["Name" + LanguageCodeSuffix] }}
			</v-card-title>
			<v-card-text>
			<div v-if="version.GroupId === 1 || version.GroupId === 2">
				{{ $t("printMaterial") }}
			</div>
			<div v-else>
				<div v-if="LanguageCodeSuffix === 'IT'">
					193 pagine <br />
					24,5x28,5 cm <br />
					copertina rigida <br />
					multilingue Italiano/Inglese<br />
					carta riciclata e certificata FSC®
				</div>
				<div v-else>
					193 pages<br />
					24.5x28.5 cm<br />
					hardcover<br />
					multilingual Italian/English<br />
					recycled and FSC® certified paper
				</div>
			</div>
			<div class="text-subtitle-1 font-weight-bold">
				<span v-if="version.GroupId">{{ $t("priceFrom") }}</span>
				<span v-else>{{ $t("common.price") }}</span>
					{{ version.Price | formatCurrency }}
			</div>
			<div v-if="version.MaxAvailability !== null">
				{{ $t("maxAvailibility", [version.RemainingAvailability]) }}
			</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<ItemDetailsDialog
					v-model="version.showDialog"
					:ItemDetails="ItemDetails"
					:Version="version">
				</ItemDetailsDialog>
				<v-btn color="primary" large text
					:disabled="version.RemainingAvailability === 0"
					@click="version.showDialog = true">
					<v-icon left>fas fa-cart-plus</v-icon>{{ $t("common.select") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import ItemDetailsDialog from "./ItemDetailsDialog";
export default {
	components: { ItemDetailsDialog },
	props: {
		ItemDetails: { type: Object, required: true }
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"availableFormats": "Formati disponibili",
			"priceFrom": "Prezzo a partire da ",
			"requestContact": "Per qualsiasi richiesta di altro formato o finitura ci potete contattare alla mail",
			"maxAvailibility": "Solo {0} stampe disponibili",
			"printMaterial": "carta Giclée 100% cotone da 310 gsm"
		},
		"en":{
			"availableFormats": "Available formats",
			"priceFrom": "Price starting from ",
			"requestContact": "For any request for another format or finish, you can contact us at",
			"maxAvailibility": "Only {0} prints available",
			"printMaterial": "310gsm 100% cotton Giclée paper"
		}
	}
</i18n>
