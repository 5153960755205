<template>
	<div>
		<v-card class="mb-5">
			<v-card-title>{{ $t("sgpshop.doYouHaveAnAccount") }}</v-card-title>
			<v-card-text>
				<v-btn to="/Login?ReturnUrl=/ShopClients/CompleteOrder" color="primary" large>
					{{ $t("common.signin") }}
				</v-btn>
			</v-card-text>
		</v-card>
		<v-stepper v-model="currentStep">
			<v-stepper-header>
				<v-stepper-step
					:complete="currentStep > 1"
					step="1"
				>
				{{ $t("headerStepCreateAccount") }}
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step
					step="2" :complete="currentStep > 2">
					{{ $t("headerStepBillingInfo") }}
				</v-stepper-step>

				<template v-if="ItemId && !IsCartolina">
					<v-divider></v-divider>
					<v-stepper-step
						step="3">
						{{ $t("headerStepDeliveryAddress") }}
					</v-stepper-step>
				</template>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<h3 text="sgpshop-h3">	{{ $t("headerStepCreateAccount") }}</h3>
					<CreateAccount @userCreated="OnStep1Complete"></CreateAccount>
				</v-stepper-content>
				<v-stepper-content step="2">
					<h3 text="sgpshop-h3">{{ $t("headerStepBillingInfo") }}</h3>
					<AddEditBillingProfileCard
						v-if="isUserAuthenticated"
						@updated="OnStep2Complete"
						:hideCancelButton="true"
						:suppressSuccessMessage="true"
						:mode="1">
					</AddEditBillingProfileCard>
					<StripeCheckout
						ref="checkoutRef"
						mode="payment"
						:pk="publishableKey"
						:line-items="lineItems"
						:success-url="successURL"
						:cancel-url="cancelURL"
						:session-id="sessionId"
						@loading="(v) => (stripeLoading = v)"
						:email="customerEmail"
					>
					</StripeCheckout>
				</v-stepper-content>
				<v-stepper-content step="3" v-if="ItemId && !IsCartolina">
					<h3 text="sgpshop-h3">{{ $t("headerStepDeliveryAddress") }}</h3>
					<AddEditShippingAddressCard v-if="isUserAuthenticated"
					@updated="OnStep3Complete"
					:hideCancelButton="true" :mode="1" :suppressSuccessMessage="true"></AddEditShippingAddressCard>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CreateAccount from "../components/CreateAccount.vue";
import AddEditBillingProfileCard from "@/components/Account/AddEditBillingProfileCard"
import AddEditShippingAddressCard from "@/components/Account/AddEditShippingAddressCard"
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
	components: { CreateAccount, AddEditBillingProfileCard, AddEditShippingAddressCard, StripeCheckout },
	computed: {
		...mapGetters([
			"isUserAuthenticated"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		ItemId() {
			return this.$route.query.ItemId;
		},
		IsCartolina() {
			return this.$route.query.Cartolina === "true";
		}
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.checkout"),
					disabled: true,
					exact: true,
					to: "/CheckoutRegister",
				}],
			currentStep: 1,

			stripeLoading: false,
			dataLoading: false,
			lineItems: [],
			successURL: null,
			cancelURL: null,
			customerEmail: null,
			clientReferenceId: null,
			sessionId: null,
		};
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ShopItemsService = new CrudClient("Shop/Items");
		this.StripePaymentsService = new CrudClient(
			"StripePayments"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		removeItem(index) {
			this.Basket.splice(index, 1);
		},

		OnStep1Complete() {
			this.currentStep++
		},
		async OnStep2Complete() {
			if (this.ItemId) {
				if (this.IsCartolina) {
					await this.RedirectToStripeCheckout();
					return;
				}

				this.currentStep++;
			} else {
				// Book NFF case, no ItemId
				this.$router.push("/TimeToChange");

				// open PDF in new tab
				const bookNffUrl = "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/NFF/Time%20To%20Change%20-%20digital%20book.pdf";
				this.openInNewTab(bookNffUrl);
			}
		},

		async OnStep3Complete() {
			// Open Item NFF file
			var itemDetails = await this.ShopItemsService.Get(this.ItemId);
			// redirect app to items page
			this.$router.push(`/TimeToChange/${itemDetails.ChapterId}/${itemDetails.ItemId}`);

			// open PDF in new tab
			this.openInNewTab(itemDetails.NFFLink);
		},

		openInNewTab(url) {
			window.open(url, "_blank", "noreferrer");
		},

		async RedirectToStripeCheckout() {
			this.sessionId = await this.StripePaymentsService.Get(
				`Postcard/${this.ItemId}/CreateSession`
			);

			this.$refs.checkoutRef.redirectToCheckout();
		},

	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Checkout",
			"headerStepCreateAccount": "Crea il tuo account",
			"headerStepDeliveryAddress": "Indirizzo di consegna",
			"headerStepBillingInfo": "Dati di fatturazione"
		},
		"en":{
			"pageTitle": "Checkout",
			"headerStepCreateAccount": "Create your account",
			"headerStepDeliveryAddress": "Delivery Address",
			"headerStepBillingInfo": "Billing Information"
		}
	}
</i18n>
