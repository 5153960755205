<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
				<v-icon slot="divider" class="secondary--text text--lighten-2"
					>fas fa-caret-right</v-icon
				>
		</v-breadcrumbs>
		<v-container>

				<v-row v-if="LoadingItemDetails">
					<v-col cols="12" md="8">
						<div>
							<v-skeleton-loader type="image" class="rounded"></v-skeleton-loader>
						</div>
					</v-col>
					<v-col cols="12" md="4">
						<v-skeleton-loader type="text, paragraph"></v-skeleton-loader>
						<v-skeleton-loader class="mt-3" type="heading"></v-skeleton-loader>
						<v-skeleton-loader class="mt-3" type="paragraph"></v-skeleton-loader>
						<v-card v-for="i in 3" :key="i" class="mb-5">
							<v-skeleton-loader
								type="card-heading, list-item-three-line, actions"></v-skeleton-loader>
						</v-card>
					</v-col>
				</v-row>

			<v-row v-if="ItemDetails">
				<v-col cols="12" md="8" class="px-2">
					<v-carousel :show-arrows="true"
						show-arrows-on-hover
						hide-delimiters
						height="auto"
						v-model="carouselCurrentIndex"
						>
						<template v-slot:next="{ on, attrs }">
							<v-btn icon
								dark
								v-bind="attrs"
								v-on="on"
								><v-icon>fas fa-chevron-right</v-icon>
							</v-btn>
						</template>
						<template v-slot:prev="{ on, attrs }">
							<v-btn icon
								dark
								v-bind="attrs"
								v-on="on"
								><v-icon>fas fa-chevron-left</v-icon>
							</v-btn>
						</template>
						<v-carousel-item>
							<img
								class="mx-auto"
								style="display:block;object-fit: contain; max-height: 800px; width:100%;"
								contain
								:src="ItemDetails.ImageUrl" />
						</v-carousel-item>
						<v-carousel-item v-if="selectedVersion">
							<CanvasRender  style="object-fit: contain; max-height: 800px; width:100%;"
								class="mx-auto"
								:EmptyBackground="false"
								:selectedPhoto="ItemDetails.ImageUrl"
								:selectedFrame="selectedFrame"
								:passepartoutWidth="selectedFrame ? selectedVersion.PassepartoutWidth : 0"
								:printWidth="selectedVersion.Width">
							</CanvasRender>
						</v-carousel-item>
						<v-carousel-item v-if="selectedVersion">
							<CanvasRender   style="object-fit: contain; max-height: 800px; width:100%;"
								class="mx-auto"
								:EmptyBackground="true"
								:selectedPhoto="ItemDetails.ImageUrl"
								:selectedFrame="selectedFrame"
								:passepartoutWidth="selectedFrame ? selectedVersion.PassepartoutWidth : 0"
								:printWidth="selectedVersion.Width">
							</CanvasRender>
						</v-carousel-item>
						<v-carousel-item v-if="selectedVersion && selectedFrame" >
							<v-img
								class="mx-auto"
								style="display:block;object-fit: contain; max-height: 800px; width:100%;"
								contain
								src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Frame/L1280825_R%20taglio.png">
							</v-img>
						</v-carousel-item>
						<v-carousel-item v-if="selectedVersion && selectedFrame" >
							<v-img
								class="mx-auto"
								style="display:block;object-fit: contain; max-height: 800px; width:100%;"
								contain
								src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Frame/L1280860_R%20taglio.png">
							</v-img>
						</v-carousel-item>
					</v-carousel>
				</v-col>
				<v-col cols="12" md="4">
					<h1 class="mb-1 grey--text text--darken-2" >{{ ItemDetails["Name" + LanguageCodeSuffix] }}</h1>

					<MultiLineContent class="photoDescription text-body-1 grey--text text--darken-3" :InputText="ItemDetails['Content' + LanguageCodeSuffix]"></MultiLineContent>

					<div class="mb-4 text-body-2">
						<div class="mb-1">
						<v-icon color="blue darken-1" small>fas fa-check</v-icon>
							{{ $t("printMaterial") }}
						</div>
						<div class="mb-1">
						<v-icon color="blue darken-1" small>fas fa-check</v-icon>
							{{ $t("limitedEdition") }}
						</div>
						<div>
						<v-icon color="blue darken-1" small>fas fa-check</v-icon>
							{{ $t("certified") }}
						</div>
					</div>

					<h3 class="text-h5 mb-3">{{ $t("availableFormats") }}</h3>
					<ShopItemCard
						:ItemDetails="ItemDetails"
						v-model="selectedVersionId"
						:frameOptionChosen="selectedFrame !== null">
					</ShopItemCard>

					<div class="grey--text text--darken-2 mb-3">
						* {{ $t("sizeWarning") }}
					</div>
<!-- Group 4, 5, 6 -->
<!-- {{ selectedVersion?.ShopItemVersionGroup.ShopItemVersionOptions }} -->
					<template  v-if="selectedVersionId && [4, 5, 6].find(n => n === selectedVersion.GroupId)">
						<h3 class="text-h5 mb-3">{{ $t("chooseFrame") }}</h3>
						<v-container fluid class="mb-3">
							<v-row
								class="pointer onBgHoverColor rounded mb-5 elevation-5"
								:class="{'yellow lighten-4' : selectedFrame === 1}"
								@click="selectedFrame = 1">
								<v-col cols="4">
									<div class="frameOption"
									:class="{
										'active': selectedFrame === 1
									}">
										<div class="frameOptionInner"
										style="background-color: black;">
										</div>
									</div>
								</v-col>
								<v-col :cols="GetBlackGroupOption.Price > 0 ? 6 : 8">
									<div>
										<!-- {{  GetBlackGroupOption["Name" + LanguageCodeSuffix] }} -->
										<h6 class="text-h6">{{ $t("frame") }}</h6>
										{{ $t("frameMaterial") }}
										<br />
										{{ $t("frameDimensions") }}

									</div>
								</v-col>
								<v-col cols="2" v-if="GetBlackGroupOption.Price > 0">
									<div class="font-weight-bold" >{{ GetBlackGroupOption.Price | formatCurrency }}</div>
								</v-col>
							</v-row>
							<v-row
								class="pointer onBgHoverColor rounded elevation-5"
								:class="{ 'yellow lighten-4 ': selectedFrame === null }"
								@click="selectedFrame = null">
								<v-col cols="4">
									<div class="frameOption"
									:class="{
										'active': selectedFrame === null
									}">
										<div class="frameOptionInner text-center" style="background-color: #DCDCDC;" >
											<v-icon size="24" style="margin-top:6px">fa-fw fas fa-empty-set</v-icon>
										</div>
									</div>
								</v-col>
								<v-col cols="8">
									<div>
										<h6 class="text-h6">
											{{ GetNoFrameGroupOption["Name" + LanguageCodeSuffix] }}
										</h6>
										<div class="font-weight-bold" v-if="GetNoFrameGroupOption.Price >0">{{ GetNoFrameGroupOption.Price | formatCurrency }}</div>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</template>
					<v-btn color="info" block
						:disabled="selectedVersionId === null"
						@click="addSelectedItemToBasket">{{ $t("sgpshop.addToBasket") }}
					</v-btn>
					<v-divider class="my-3"></v-divider>
					<div class="text-body-2">
						{{ $t("requestContact") }}
						<span class="font-weight-bold">produzione@sgpitalia.com</span>
					</div>
					<v-container fluid class="ma-0 pa-0 mt-5">
						<v-row dense v-if="LoadingItemDetails">
							<v-col cols="12" md="6"><v-skeleton-loader type="card"></v-skeleton-loader></v-col>
							<v-col cols="12" md="6"><v-skeleton-loader type="card"></v-skeleton-loader></v-col>
						</v-row>
						<template  v-if="chapter && chapter.ShopItems"  >
							<v-row dense>
								<v-col cols="12">
									<h3 class="text-h5" style="font-size: 2.3rem;">{{ $t("sameCollection") }}</h3>
								</v-col>
							</v-row>
							<v-row
								dense
								class="ma-0">
								<v-col v-if="prevItem"
									cols="12" lg="6"
										>
									<router-link
										class="NoDecoration grey--text text--darken-2"
										:to="`/${ProjectDetails.FriendlyUrl}/${ItemDetails.ChapterId}/${prevItem.ItemId}`">
										<div style="height: 240px;"
											class="onBgHoverColor pa-5 rounded">
											<img :src="prevItem.ImagePreviewUrl"
												class="frame mx-auto"/>
										</div>
										<div class="text-center text-h6 " >
												{{ prevItem['Name' + LanguageCodeSuffix] }}
											</div>
									</router-link>
								</v-col>
								<v-col v-if="nextItem"
									cols="12" lg="6"
										>
									<router-link
											class="NoDecoration grey--text text--darken-2"
											:to="`/${ProjectDetails.FriendlyUrl}/${ItemDetails.ChapterId}/${nextItem.ItemId}`">
										<div style="height: 240px;"
											class="pa-5 rounded onBgHoverColor">
											<img :src="nextItem.ImagePreviewUrl"
												class="frame mx-auto"/>
										</div>
										<div class="text-center text-h6 " >
											{{ nextItem['Name' + LanguageCodeSuffix] }}
										</div>
									</router-link>
								</v-col>
							</v-row>
						</template>
					</v-container>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import MultiLineContent from "@/components/Shared/Common/MultiLineContent.vue";
import ShopItemCard from "./ShopItemCard.vue";
import CanvasRender from "./CanvasRender.vue";

export default {
	components: { MultiLineContent, ShopItemCard, CanvasRender },
	metaInfo() {
		return {
			title: this.chapter?.["Name" + this.LanguageCodeSuffix] + ": " + this.ItemDetails?.["Name" + this.LanguageCodeSuffix],
			meta: [{
				name: "description",
				content: this.chapter?.["Name" + this.LanguageCodeSuffix] + ": " + this.ItemDetails?.["Name" + this.LanguageCodeSuffix]
			}],
		};
	},
	data() {
		return {
			LoadingChapters: false,
			chapter: null,
			LoadingProject: false,
			ProjectDetails: null,
			LoadingItemDetails: false,
			ItemDetails: null,
			showInfoDialog: false,
			breadcrumbsItems: [],
			selectedVersionId: null,
			selectedFrame: null,
			carouselCurrentIndex: 0,
		}
	},
	computed: {
		...mapGetters(["MainProject"]),

		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		ItemId() {
			return parseInt(this.$route.params.ItemId);
		},
		nextItem() {
			if (!this.chapter?.ShopItems) return null;
			const index = this.chapter?.ShopItems.findIndex(c => c.ItemId === this.ItemId);
			return (index + 1 === this.chapter?.ShopItems.length) ? null : this.chapter?.ShopItems[index + 1]
		},
		prevItem() {
			if (!this.chapter?.ShopItems) return null;
			const index = this.chapter?.ShopItems.findIndex(c => c.ItemId === this.ItemId);
			return (index === 0) ? null : this.chapter?.ShopItems[index - 1]
		},
		selectedVersion() {
			if (this.selectedVersionId === null) { return null; }

			return this.ItemDetails.ShopItemVersions.find(v => v.VersionId === this.selectedVersionId);
		},

		GetBlackGroupOption() {
			if (this.selectedVersionId === null) { return null; }
			switch (this.selectedVersion.GroupId) {
				case 4:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 9);
				case 5:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 10);
				case 6:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 11);
			}

			return null;
		},

		GetNoFrameGroupOption() {
			if (this.selectedVersionId === null) { return null; }
			switch (this.selectedVersion.GroupId) {
				case 4:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 12);
				case 5:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 13);
				case 6:
					return this.selectedVersion.ShopItemVersionGroup.ShopItemVersionOptions.find(o => o.OptionId === 14);
			}

			return null;
		}
	},

	watch: {
		selectedVersionId: async function (val) {
			await this.$nextTick();
			this.carouselCurrentIndex = 1
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ShopItemsService = new CrudClient("Shop/Items");
		this.BillingProfileService = new CrudClient(
			"SGP/Client/BillingProfilesSelf"
		);
	},
	async mounted() {
		let projectId;
		if (this.$route.params.ProjectFriendlyUrl.toLowerCase() === "stefanoguindani") {
			projectId = 2;
			await this.SetProject(projectId);
			this.ProjectDetails = this.MainProject;
		}
		await this.LoadItemDetails();
		await this.LoadChapterDetails(this.ItemDetails.ChapterId);

		this.breadcrumbsItems.push({
			text: this.ProjectDetails.NameIT,
			disabled: false,
			exact: true,
			to: `/${this.ProjectDetails.FriendlyUrl}`,
		});

		if (this.$route.query.from === "gallery") {
			this.breadcrumbsItems.push({
				text: "Gallery",
				disabled: false,
				exact: true,
				to: `/${this.ProjectDetails.FriendlyUrl}/Gallery`,
			});
		} else {
			this.breadcrumbsItems.push({
				text: this.chapter["Name" + this.LanguageCodeSuffix],
				disabled: false,
				exact: true,
				to: `/${this.ProjectDetails.FriendlyUrl}/${this.chapter.ChapterId}`
			});
		}
		this.breadcrumbsItems.push({
			text: this.ItemDetails["Name" + this.LanguageCodeSuffix],
			disabled: true,
			exact: true,
			to: `/${this.ProjectDetails.FriendlyUrl}/${this.chapter.ChapterId}/${this.ItemDetails.ItemId}`
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "AddItemToBasket", "SetProject"]),

		async LoadItemDetails() {
			try {
				this.LoadingItemDetails = true;
				this.ItemDetails = await this.ShopItemsService.GetSelectedFields(this.$route.params.ItemId,
					`*, ShopItemVersions.*,
ShopItemVersions.ShopItemVersionGroup.*, 
ShopItemVersions.ShopItemVersionGroup.ShopItemVersionOptions.* 
				`);

				this.ItemDetails.ShopItemVersions = this.ItemDetails.ShopItemVersions
					.filter(version => version.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				this.ItemDetails.ShopItemVersions.forEach(element => {
					this.$set(element, "showDialog", false);
				});
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingItemDetails = false;
			}
		},
		addSelectedItemToBasket() {
			let selectedOption;
			if (this.selectedFrame === null) {
				selectedOption = this.GetNoFrameGroupOption
			} else {
				selectedOption = this.GetBlackGroupOption;
			}

			const basketItem = {
				item: {
					item: this.ItemDetails,
					version: this.selectedVersion,
					selectedOption: {
						OptionId: selectedOption?.OptionId,
						Price: selectedOption?.Price ?? 0,
						NameIT: selectedOption?.NameIT,
						NameEN: selectedOption?.NameEN,
						DeliveryPrice: selectedOption?.DeliveryPrice ?? 0
					}
				},
				quantity: 1
			};
			this.$log.debug(basketItem);

			this.AddItemToBasket(basketItem);

			this.$emit("input", false);
			this.snackSuccess({ Text: this.$t("itemAddedToBasket") });
		},

		async LoadChapterDetails(chapterId) {
			try {
				this.LoadingChapters = true;

				this.chapter = await this.ProjectChaptersService.GetSelectedFields(chapterId, "ChapterId, NameIT, NameEN, ShopItems.*");
				this.chapter.ShopItems = this.chapter.ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.frame {
	max-width: 100%;
	max-height: 100%;
	display: block;
	object-fit: contain;
	border: black solid 3px;
	padding: 8px;
	background-color: #fefefe;
	box-shadow: rgba(0, 0, 0, 0.35) 1.95px 1.95px 2.6px;
}

.frameOption {
	$margin: 5px;
	$border: 3px;
	cursor: pointer;
	.frameOptionInner{
		margin:$margin;
		border: solid 2px #DCDCDC;
		height: 40px;
		width: 40px;
		border-radius: 50%;
	}
	height:(40 - 2 + 2*$margin + 2* $border);
	width:(40 - 2 + 2*$margin + 2* $border);
	border-radius: 50%;
	border: $border solid transparent;

	&:hover{
		border:$border solid #ABABAB;
	}

	&.active{
		border:$border solid #ABABAB;
	}
}
</style>
<i18n>
	{
		"it":{
			"availableFormats": "Scegli il formato",
			"requestContact": "Per qualsiasi richiesta di altro formato o finitura ci potete contattare alla mail",
			"maxAvailibility": "Solo {0} stampe disponibili",
			"chooseFrame": "Finitura",
			"printMaterial": "Stampa fine art su carta Giclée 100% cotone da 310 gsm",
			"limitedEdition": "Edizione limitata",
			"certified": "Certificato di autenticità",
			"itemAddedToBasket": "Hai aggiunto il prodotto nel tuo carrello!",
			"sameCollection": "Altre foto",
			"sizeWarning": "Le dimensioni possono variare di 5 cm a causa delle proporzioni dello scatto originale.",
			"frame": "Cornice",
			"frameDimensions": "dimensioni: 0,7cm, profondità di 0,35 cm",
			"frameMaterial": "materiale: alluminio impiallacciata legno"
		},
		"en":{
			"availableFormats": "Available formats",
			"requestContact": "For any request for another format or finish, you can contact us at",
			"maxAvailibility": "Only {0} prints available",
			"chooseFrame": "Finish",
			"printMaterial": "Fine Art prints on 310gsm 100% cotton Giclée paper",
			"limitedEdition": "Limited edition",
			"certified": "Authentic certified artwork",
			"itemAddedToBasket": "You've successfully added this item to your shopping cart!",
			"sameCollection": "See also",
			"sizeWarning": "Size may vary by 5cm due to the aspect ratio of the original shot.",
			"frame": "Frame",
			"frameDimensions": "dimensioni: 0,7cm, depth 0,35 cm",
			"frameMaterial": "material: wood veneered aluminium"
		}
	}
</i18n>
