<template>
		<SimpleFormCard
			ref="formCreateSubAccount"
			:formContent="formCreateSubAccount"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			@submitForm="CreateUser"
			:showCancelButton="false"
			:locali18n="$i18n"
		>
		</SimpleFormCard>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	$_veeValidate: { validator: "CreateUser" },
	components: { SimpleFormCard },
	data() {
		return {
			metadataLoaded: true,
			metadata: {
				Name: {
					ClientValidators: "required",
					MaxLength: null,
				},
				OwnerAccountId: {
					ClientValidators: "required",
					MaxLength: null,
				},
				Surname: {
					ClientValidators: "required",
					MaxLength: null,
				},
				UserName: {
					ClientValidators: "required|min:6",
					MaxLength: null,
				},
				MobilePhoneNo: {
					ClientValidators: "max:20",
					MaxLength: null,
				},
				Email: {
					ClientValidators: "required|email",
					MaxLength: null,
				},
				Password: {
					ClientValidators: "required|min:8",
					MaxLength: null,
				},
			},
			formCreateSubAccount: new CrudFormData("formCreateSubAccount", [
				"Name",
				"Surname",
				"Email",
				"Password",
				{
					type: "v-text-field",
					FieldName: "Password",
					"input-type": "password",
				},
				"MobilePhoneNo"
			]),
			LoadingUsers: false,
		};
	},
	props: {
	},
	watch: {
		// value: async function (val) {
		// 	this.$log.debug("watch value");
		// 	this.$log.debug(val);

		// 	if (val) {
		// 		await this.LoadUserList();
		// 	}

		// 	if (!val) this.ClearNewUserDialogForm();
		// },
	},
	created() {
		this.ApplicationUsersService = new CrudClient("Shop/Users");
	},
	async mounted() {
		// this.$log.info("Create Sub-Account Dialog mounted");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "Signin"]),
		async CreateUser(dto) {
			this.formCreateSubAccount.FormLoading = true;

			try {
				const userId = await this.ApplicationUsersService.Post(
					null,
					dto,
					"CreateShopClient",
					true
				);

				const authRes = await this.Signin({
					username: dto.Email,
					password: dto.Password,
					rememberMe: true,
				});

				if (authRes.error) {
					this.$log.error(authRes.error);
				}

				this.$emit("userCreated", userId);
				this.snackSuccess({ Text: this.$t("createuser_Success") });
			} catch (error) {
				this.snackError({ Text: this.$t("createuser_Error") });
			} finally {
				this.formCreateSubAccount.FormLoading = false;
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formCreateSubAccount) {
				this.$log.debug("formCreateSubAccount ResetForm called ");
				this.$refs.formCreateSubAccount.ResetForm();
			}
		}
	},
};
</script>

<i18n>
{
    "it": {
		"addUserDialog_Header": "Crea Account Collegato",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Nome",
		"Surname": "Cognome",
		"formCreateSubAccount": {
			"Name": "Nome",
			"Surname": "Cognome",
			"Email": "E-mail",
			"Password": "Password",
			"MobilePhoneNo": "Numero di telefono"
		}
    },
    "en": {
		"addUserDialog_Header": "Crea Account Collegato",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Name",
		"Surname": "Surname",
		"formCreateSubAccount": {
			"Name": "Name",
			"Surname": "Surname",
			"Email": "E-mail",
			"Password": "Password",
			"MobilePhoneNo": "Phone number"
		}
    }
}
</i18n>
