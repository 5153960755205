<template>
	<v-autocomplete
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="onInput"
		:loading="loading"
		:items="items"
		:item-text="displayField"
		item-value="Id"
		:search-input.sync="search"
		:label="
			$attrs.label ||  $t('Nation')
		"
	></v-autocomplete>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			loading: false,
			items: [],
			search: null,
		};
	},

	props: {
		value: {
			type: Number,
			default: null,
		}
	},
	computed: {
		displayField() {
			return (this.$root.$i18n.locale === "it") ? "DescrizioneNazione" : "CountryNameEn";
		}
		//  : displayField=""
	},

	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val && val !== this.value && val.length > 1 && (await this.doSearch(val));
		},
		value: {
			immediate: true,
			handler: async function (newVal) {
				await this.handler(newVal);
			}
		},
	},

	created() {
		this.CountriesService = new CrudClient("Countries");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		onInput(param) {
			this.$emit("input", param);
		},

		async handler(newVal) {
			if (!newVal) return;

			// created is called after watch immediate
			const CountriesService = new CrudClient("Countries");
			if (!this.items.find((i) => i.ComuneId === newVal)) {
				// Programmatic value set
				try {
					this.loading = true;
					const res = await CountriesService.GetSelectedFields(
						newVal,
						"DescrizioneNazione, CountryNameEn, Id",
						null,
						false
					);

					this.search = res[this.displayField];
				} catch (error) {
					this.snackError({ Text: this.$t("common.error.cannotLoadData") });
					this.$captureError(error);
				} finally {
					this.loading = false;
				}
			}
		},

		async doSearch(val) {
			try {
				this.loading = true;
				this.items = (
					await this.CountriesService.GetPaged({
						limit: 10,
						skip: 0,
						orderBy: this.displayField,
						filter: `${this.displayField}:sw:${val}`,
						fields: "DescrizioneNazione, CountryNameEn, Id",
						addApplicationId: false,
					})
				).Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			}

			this.loading = false;
		},
	},
};
</script>

<i18n>
{
    "it": {
		"Nation": "Nazione",
		"Citizenship": "Cittadinanza"
	},
	"en": {
		"Nation": "Nation",
		"Citizenship": "Citizenship"
	}
}
</i18n>
