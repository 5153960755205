<template>
	<v-card class="mb-5">
		<v-card-title>
			{{ $t("title") }}
		</v-card-title>
		<v-card-text>
			<div>
				{{ $t("productDescription") }}
			</div>
			<div class="mt-3">
				<v-icon>fas fa-copyright</v-icon>
				{{ $t("copyright") }}
			</div>
			<v-container fluid>
				<v-row>
					<v-col cols="4">
						<v-img src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/donate-now-thsn.png"
						contain ></v-img>
					</v-col>
					<v-col cols="8">
						<div class="text-subtitle-1 font-weight-bold">
							{{$t("donationAmount") }}
						</div>
					</v-col>
				</v-row>
			</v-container>
			<div>
				{{ $t("donationInfo") }}
				<v-icon color="primary" @click="showInfoDialog = true">fas fa-info-circle</v-icon>
				<DonationInfoDialog  v-model="showInfoDialog"></DonationInfoDialog>
			</div>
		</v-card-text>
		<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn large color="primary"
					@click="OpenBuyPostcardDialog">
					{{ $t("common.continue") }}
				</v-btn>
		</v-card-actions>
		<v-dialog v-model="showBuyPostcardDialog"
			@keydown.esc="CloseBuyPostcardDialog"
			max-width="500" >
			<v-card>
				<v-card-title>
					{{ $t("title") }}
				</v-card-title>
				<v-card-text>

					Fai la tua donaziozione sul sito THSN e poi inserisci il codice per ricevere la cartolina
					<br />
					<div class="text-center">
					<v-btn large dark
						href="https://donate.thehumansafetynet.org/timetochange-postcard/"
						target="_blank"
						color="#b32625">{{ $t("sgpshop.donate") }}
					</v-btn>
					</div>
					<div>
						<v-text-field v-model="verificaionCode" :label="$t('verificationCode')"></v-text-field>
						<v-text-field v-model="email" :label="$t('yourEmail')" ></v-text-field>
					</div>

				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="CloseBuyPostcardDialog">{{ $t("common.cancel") }}</v-btn>
					<v-btn @click="ReceivePostcard" color="primary">{{ $t("getPostcard") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import { mapActions } from "vuex";
import DonationInfoDialog from "../components/DonationInfoDialog.vue";
import CrudClient from "@/services/CrudClient/";

export default {
	components: { DonationInfoDialog },
	data() {
		return {
			showInfoDialog: false,

			dataLoading: false,
			showBuyPostcardDialog: false,
			verificaionCode: null,
			email: null
		}
	},
	props: {
		ItemDetails: { type: Object }
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	created() {
		this.PostcardService = new CrudClient(
			"Shop/Postcard"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		OpenBuyPostcardDialog() {
			this.showBuyPostcardDialog = true;
		},
		CloseBuyPostcardDialog() {
			this.showBuyPostcardDialog = false;
		},

		validateEmail(email) {
			var re = /\S+@\S+\.\S+/;
			return re.test(email);
		},

		async ReceivePostcard() {
			this.email = this.email.trim();
			this.verificaionCode = this.verificaionCode.trim();

			if (!this.validateEmail(this.email)) {
				this.snackError({ Text: "Inserisci un indirizzo email valido" });
				return;
			}
			const dto = {
				VerificationCode: this.verificaionCode,
				Email: this.email
			};

			try {
				await this.PostcardService.Post(this.ItemDetails.ItemId, dto, "Complete");
				this.$router.push(`/TimeToChange/Postcard/${this.ItemDetails.ItemId}/Success`);
			} catch (error) {
				this.snackError({ Text: "Controlla il codice di verifica" });
			}
		}
	}
}
</script>
<i18n>
	{
		"it":{
"title": "Cartolina (File JPG)",
"productDescription":"Acquistando la cartolina, riceverai un file di risoluzione 1400x933px (10x15cm)",
"copyright": "L’utilizzo del file è per solo uso privato. L’immagine acquistata è coperta da copyright, non potrà essere venduta, ceduta in licenza, utilizzata per commercializzare prodotti e/o servizi.",
"donationAmount": "Donazione a partire da €5,00",
"donationInfo": "L’intero ricavato verrà devoluto alla Fondazione per sostenere i progetti Aula 162 e ESIR.",
"getPostcard": "Ricevi la cartolina",
"yourEmail": "La tua email",
"verificationCode": "Codice di verifica"
		},
		"en": {
"title": "Postcard (JPG File)",
"productDescription":"By purchasing the postcard, you will receive a 1400x933px resolution file (10x15cm).",
"copyright": "The use of the file is for private use only. The purchased image is covered by copyright, it cannot be sold, licensed, used to market products and/or services.",
"donationAmount": "Donation starting from €5,00",
"donationInfo": "The entire proceeds will be donated to the Foundation to support the Aula 162 and ESIR projects.",
"getPostcard": "Get your postcard",
"yourEmail": "Your e-mail address",
"verificationCode": "Verification Code"
		}
	}
</i18n>
