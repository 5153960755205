<template>
	<v-navigation-drawer app :right="right" v-model="showDrawer">
		<v-card color="secondary" flat tile dark class="pa-3" v-if="isUserAuthenticated">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12">
						<router-link class="profileLink" to="/Account/Profile">
							<user-avatar-fullname :size="44" :showDisplayName="true" textClass="white-text ml-3"
								:profile="UserProfile"></user-avatar-fullname>
						</router-link>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-list dense class="pt-0 text-capitalize">
			<!-- menuLinks: [{ title: "Home", icon: "fas fa-fw fa-home", to: "/" }], -->
			<v-list-item :to="Logo.To" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-home</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Home</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-if="!isUserAuthenticated" to="/Login" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-in-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("common.login") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset v-if="isUserAuthenticated"></v-divider>
			<v-list-item v-if="isUserInRole('ShopClient')" to="/ShopClients">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-user-lock</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ $t("sgpshop.shopClients") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			<v-divider inset v-if="isUserAuthenticated"></v-divider>
			<v-list-item v-if="isUserAuthenticated" @click="DoSignout">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-out-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item to="/Support">
				<v-list-item-action>
					<v-icon>far fa-life-ring</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{ $t("support") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";

export default {
	name: "side-bar",
	components: { UserAvatarFullname },
	data() {
		return {
			menuLinks: [{ title: "Home", icon: "fas fa-fw fa-home", to: "/" }],
			right: false,
			showDrawer: false,
		};
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"isUserAuthenticated",
			"isUserInRole",
			"isUserInRoles",
			"MainProject"
		]),
		Logo() {
			if (this.MainProject?.ProjectId === 2) {
				return {
					LogoSrc: "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project02/Logo/StefanoGuindaniTextLogo.png",
					To: "/StefanoGuindani",
					Height: 60,
					Width: 130
				}
			}
			return {
				LogoSrc: "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/Logo/BG4SDGs_logo_003.png",
				To: "/TimeToChange",
				Height: 60,
				Width: 60
			}
		},
	},
	mounted() {
	},
	methods: {
		...mapActions(["SignOut"]),

		ToggleDrawer() {
			this.showDrawer = !this.showDrawer;
		},

		DoSignout() {
			this.SignOut();
			this.$router.push("/Login");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

a.profileLink {
	color: white;
	text-decoration: none;
	&:hover {
		color: $primary;
	}

	&>div {
		display: flex;
		align-items: center;
	}
}
</style>

<i18n>
{
	"it": {
		"AdminSection": "Area Admin",
		"ClientSection": "Area Clienti",
		"SystemAdmin": "System Admin",
		"UserManagement": "Gestione Utenti",
		"support": "Assistenza"
	}
}
</i18n>
