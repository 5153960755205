<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<v-icon slot="divider" class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
		</v-breadcrumbs>
		<h1 v-if="ProjectDetails" class="sgpshop-h1 text-uppercase">{{ ProjectDetails["Name" + LanguageCodeSuffix] }} Gallery</h1>
		<div v-for="i in 17" :key="i">
			<GalleryChapter :ChapterId="i"></GalleryChapter>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import GalleryChapter from "./GalleryChapter";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "ProjectGallery",
	components: {
		GalleryChapter
	},
	data() {
		return {
			LoadingChapters: false,
			ProjectChapters: null,
			ChapterBlobs: [],
			LoadingProject: false,
			ProjectDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		const sustainabilityProjecId = 1;

		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;

		this.SetBreadcrumbs(sustainabilityProjecId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		SetBreadcrumbs() {
			this.breadcrumbsItems.push({
				text: "Time To Change",
				disabled: false,
				exact: true,
				to: "/TimeToChange",
			});

			this.breadcrumbsItems.push({
				text: "Gallery",
				disabled: true,
				exact: true,
				to: "/TimeToChange/Gallery",
			});
		},

	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Time To Change Foto Gallery",
			"description": "Visualizza tutte le foto del progetto Time To Change."
		},
		"en":{
			"pageTitle": "Time To Change Phpoto Gallery",
			"description": "See all the photos of the Time To Change project."
		}
	}
</i18n>
