<template>
	<div>
		<v-container v-if="!LoadingDataComplete">
			<v-row>
				<v-col class="">
					<v-responsive class="grey lighten-2 rounded my-5 mx-0 "
							:aspect-ratio="$vuetify.breakpoint.mdAndUp ? 2.2 : 1.6">
					</v-responsive>
				</v-col>
			</v-row>
			<v-row dense>
					<v-col>
						<v-skeleton-loader type="heading"></v-skeleton-loader>
					</v-col>
				</v-row>
			<v-row dense >
				<v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i" >
					<v-skeleton-loader type="card" class="ma-2 pa-0"></v-skeleton-loader>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-else>
			<v-row>
				<v-col>
					<v-img src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project02/18/13_S1630623_R.JPG"
						class="rounded my-5 mx-0"
						:aspect-ratio="$vuetify.breakpoint.mdAndUp ? 1.6 : 1.6"
						cover>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-col>
								<h1 class="sgpshop-h1 text-uppercase white--text mb-2" >{{ ProjectDetails["Name" + LanguageCodeSuffix] }}</h1>
								<div class="text-center font-weight-normal white--text" style="font-size: 19px;">{{ ProjectDetails["Content" + LanguageCodeSuffix] }}</div>
							</v-col>
						</v-row>
					</v-img>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col>
					<h1 class="sgpshop-h2" style="font-size: 2.3rem;">{{$t("collections")}}</h1>
				</v-col>
			</v-row>
			<v-row dense >
				<v-col cols="12" md="6" lg="4" v-for="chapter in ProjectChapters" :key="chapter.ChapterId">
					<v-card :to="`/${ProjectDetails.FriendlyUrl}/${chapter.ChapterId}`" class="ma-2 pa-0">
						<v-card-text class="pa-0">
							<v-img class="roundedTop" v-if="chapter.ShopItems.length > 0"
										aspect-ratio="1.3"
										:src="chapter.ShopItems[0].ImagePreviewUrl">
									</v-img>
									<v-responsive v-else aspect-ratio="1.3" class="roundedTop grey lighten-2">
									</v-responsive>
							</v-card-text>
						<v-card-title class="text-no-wrap text-truncate">
							{{ chapter["Name" + LanguageCodeSuffix] }}
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="ProjectHomeItems.length > 0">
				<v-col>
					<h1 class="sgpshop-h2" style="font-size: 2.3rem;">{{ $t("bestSelling") }}</h1>
				</v-col>
			</v-row>
			<v-row v-if="ProjectHomeItems.length > 0"
				class="ma-0">
				<v-col
				cols="6" md="3"
					v-for="item in ProjectHomeItems" :key="item.HomePageItemId">
					<router-link
						class="NoDecoration grey--text text--darken-2"
						:to="`/${ProjectDetails.FriendlyUrl}/${item.ShopItem.ChapterId}/${item.ItemId}`">
						<div style="height: 240px;"
							class="onBgHoverColor pa-5 rounded">
							<img :src="item.ShopItem.ImagePreviewUrl"
								class="frame mx-auto"/>
						</div>
					</router-link>
				</v-col>
			</v-row>
		</v-container>

		</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
export default {
	metaInfo() {
		return {
			title: this.ProjectDetails?.["Name" + this.LanguageCodeSuffix],
			meta: [{ name: "description", content: this.ProjectDetails?.["Content" + this.LanguageCodeSuffix] }],
		};
	},
	name: "ProjectDetails",
	components: {
	},
	data() {
		return {
			ProjectChapters: null,
			LoadingDataComplete: false,
			ProjectDetails: null,
			ProjectHomeItems: null,
			LoadingHomeItems: false,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ProjectHomeItemsService = new CrudClient("Shop/HomeSelectedItems");
	},
	async mounted() {
		let projectId;
		if (this.$route.params.ProjectFriendlyUrl.toLowerCase() === "stefanoguindani") {
			projectId = 2;
			await this.SetProject(projectId);
			this.ProjectDetails = this.MainProject;
		}

		await this.SetBreadcrumbs(projectId);
		await this.LoadProjectChapters(projectId);
		await this.LoadProjectHomeItems(projectId);
		this.LoadingDataComplete = true;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		async LoadProjectChapters(projectId) {
			try {
				const res = await this.ProjectChaptersService.GetPaged({
					limit: 0,
					fields: "ChapterId, NameIT, NameEN, ShopItems.ImagePreviewUrl, ShopItems.IsActive, ShopItems.SortOrder",
					orderBy: "SortOrder",
					urlPostFix: `Project/${projectId}`
				});

				this.ProjectChapters = res.Data;

				for (let i = 0; i < this.ProjectChapters.length; i++) {
					this.ProjectChapters[i].ShopItems = this.ProjectChapters[i]
						.ShopItems.filter(item => item.IsActive === true)
						.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
			}
		},

		async SetBreadcrumbs() {
			this.breadcrumbsItems.push({
				text: this.ProjectDetails.NameIT,
				disabled: true,
				exact: true,
				to: "/" + this.ProjectDetails.FriendlyUrl,
			});
		},

		async LoadProjectHomeItems(projectId) {
			try {
				this.LoadingHomeItems = true;
				const res = await this.ProjectHomeItemsService.GetPaged({
					limit: 0,
					fields: "*, ShopItem.ImagePreviewUrl, ShopItem.ChapterId",
					urlPostFix: "Project/" + projectId
				});

				this.ProjectHomeItems = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingHomeItems = false;
			}
		}
	}
}
</script>
<style scoped>
.frame{
	max-width:100%;
	max-height: 100%;
	display: block;
	object-fit: contain;
	border:black solid 3px;
	padding: 8px;
	background-color: #fefefe;
	box-shadow: rgba(0, 0, 0, 0.35) 1.95px 1.95px 2.6px;
}
</style>
<i18n>
	{
		"it":{
			"bestSelling": "Le più vendute",
			"collections": "Collections"
		},
		"en":{
			"bestSelling": "Most popular",
			"collections": "Collections"
		}
	}
</i18n>
