<template>
	<div>
		<v-card class="mb-5">
			<v-card-title>{{ $t("sgpshop.doYouHaveAnAccount") }}</v-card-title>
			<v-card-text>
				<v-btn to="/Login?ReturnUrl=/ShopClients/CompleteOrder" color="primary" large>
					{{ $t("common.signin") }} </v-btn>
			</v-card-text>
		</v-card>
		<v-stepper v-model="currentStep">
			<v-stepper-header>
				<v-stepper-step
					:complete="currentStep > 1"
					step="1"
				>
				{{ $t("headerStepCreateAccount") }}
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step
					step="2" :complete="currentStep > 2">
					{{ $t("headerStepBillingInfo") }}
				</v-stepper-step>
				<v-divider v-if="!BasketIsAllDigitalCopies"></v-divider>
				<v-stepper-step
						v-if="!BasketIsAllDigitalCopies"
						step="3"
					>
					{{ $t("headerStepDeliveryAddress") }}
					</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<h3 text="sgpshop-h3">	{{ $t("headerStepCreateAccount") }}</h3>
					<CreateAccount @userCreated="OnStep1Complete"></CreateAccount>
				</v-stepper-content>
				<v-stepper-content step="2">
					<h3 text="sgpshop-h3">{{ $t("headerStepBillingInfo") }}</h3>

					<AddEditBillingProfileCard
						v-if="isUserAuthenticated" @updated="OnStep2Complete"
						:hideCancelButton="true" :suppressSuccessMessage="true" :mode="1">
					</AddEditBillingProfileCard>

				</v-stepper-content>
				<v-stepper-content step="3" v-if="!BasketIsAllDigitalCopies">
					<h3 text="sgpshop-h3">{{ $t("headerStepDeliveryAddress") }}</h3>
					<AddEditShippingAddressCard v-if="isUserAuthenticated" @updated="OnStep3Complete"
					:hideCancelButton="true" :mode="1" :suppressSuccessMessage="true"></AddEditShippingAddressCard>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CreateAccount from "./components/CreateAccount.vue";
import AddEditShippingAddressCard from "@/components/Account/AddEditShippingAddressCard"
import AddEditBillingProfileCard from "@/components/Account/AddEditBillingProfileCard"
export default {
	components: { CreateAccount, AddEditShippingAddressCard, AddEditBillingProfileCard },
	computed: {
		...mapGetters([
			"isUserAuthenticated",
			"isUserInRole",
			"BasketIsAllDigitalCopies"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.checkout"),
					disabled: true,
					exact: true,
					to: "/CheckoutRegister",
				}],
			currentStep: 1
		};
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ShopItemsService = new CrudClient("Shop/Items");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		removeItem(index) {
			this.Basket.splice(index, 1);
		},

		OnStep1Complete() {
			this.currentStep++
		},
		OnStep2Complete() {
			if (this.BasketIsAllDigitalCopies) {
				this.$router.push("/ShopClients/CompleteOrder");
			} else {
				this.currentStep++;
			}
		},
		OnStep3Complete() {
			this.$router.push("/ShopClients/CompleteOrder")
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Checkout",
			"headerStepCreateAccount": "Crea il tuo account",
			"headerStepDeliveryAddress": "Indirizzo di consegna",
			"headerStepBillingInfo": "Dati di fatturazione"
		},
		"en":{
			"pageTitle": "Checkout",
			"headerStepCreateAccount": "Create your account",
			"headerStepDeliveryAddress": "Delivery Address",
			"headerStepBillingInfo": "Billing Information"
		}
	}
</i18n>
