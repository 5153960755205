<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="4">
					<!-- <v-img aspect-ratio="1.5" class="rounded elevation-5"  src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/p1.jpg"></v-img> -->
				</v-col>
				<v-col cols="4" class="pa-8">
					<v-img src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/donate-now-thsn.png"></v-img>
				</v-col>
				<v-col cols="4">
					<!-- <v-img  aspect-ratio="1.5" class="rounded elevation-5"  src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/p2.jpg"></v-img> -->
				</v-col>
			</v-row>
			<v-row>
				<div v-if="this.LanguageCodeSuffix === 'IT'">
					The Human Safety Net è stata fondata nel 2017 come iniziativa globale di Generali per le comunità. La Fondazione estende ai soggetti più vulnerabili delle nostre comunità lo scopo di Generali di aiutare le persone a costruire un futuro più sicuro e sostenibile prendendosi cura delle loro vite e dei loro sogni. Ciò significa lavorare per il raggiungimento di alcuni degli Obiettivi di Sviluppo Sostenibile legati alla sfera sociale La nostra missione è liberare il potenziale delle persone che vivono in condizioni di vulnerabilità, affinché possano trasformare la vita delle loro famiglie e delle loro comunità. I programmi di The Human Safety Net sostengono le famiglie in condizioni di vulnerabilità con bambini piccoli (0-6 anni) e integrano i rifugiati nella comunità di accoglienza attraverso il lavoro e l’imprenditorialità. Per fare questo, uniamo le nostre forze a quelle delle organizzazioni non governative e del settore privato in Europa, Asia e Sud America. Siamo una rete aperta alla collaborazione con imprese, società e fondazioni che condividono i nostri stessi obiettivi. The Human Safety Net è attiva in 24 Paesi con 77 ONG partner.
				</div>
				<div v-else>
					The Human Safety Net was founded in 2017 as Generali's global initiative for communities. The Foundation extends Generali's purpose of helping people build a safer and more sustainable future by taking care of their lives and dreams to the most vulnerable in our communities. This means working toward achieving some of the Sustainable Development Goals related to the social sphere Our mission is to unlock the potential of people living in vulnerable conditions so that they can transform the lives of their families and communities. The Human Safety Net's programs support families in vulnerable conditions with young children (0-6 years old) and integrate refugees into the host community through employment and entrepreneurship. To do this, we join forces with nongovernmental organizations and the private sector in Europe, Asia and South America. We are a network open to collaboration with businesses, corporations and foundations that share our goals. The Human Safety Net is active in 24 countries with 77 NGO partners.
				</div>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {

	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	async mounted() {
		const sustainabilityProjecId = 1;
		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),
	}
}
</script>
