const index = () => import(/* webpackChunkName: "ShopClients" */ "./Index");
const orders = () => import(/* webpackChunkName: "ShopClients" */ "./Orders");
const orderDetails = () => import(/* webpackChunkName: "ShopClients" */ "./OrderDetails");
const completeOrder = () => import(/* webpackChunkName: "ShopClients" */ "./CompleteOrder");
const routes = [{
	path: "/ShopClients",
	component: index
},
{
	path: "/ShopClients/Orders",
	component: orders
},
{
	path: "/ShopClients/Orders/:OrderId",
	component: orderDetails
},
{
	path: "/ShopClients/CompleteOrder",
	component: completeOrder
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "ShopClient"
	};
}
);

export default routes;
