<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
				<v-icon slot="divider" class="secondary--text text--lighten-2"
					>fas fa-caret-right</v-icon
				>
		</v-breadcrumbs>
		<v-container>
			<v-row v-if="LoadingItemDetails">
				<v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
				<v-col></v-col>
				<v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
			</v-row>
			<v-row v-if="chapter && chapter.ShopItems"  justify="center">
				<v-col v-if="prevItem">
					<router-link :to="`/TimeToChange/${ItemDetails.ChapterId}/${prevItem.ItemId}`">
						<span
							style="display:inline-block"
							class="navigationChapters mr-3 grey--text text--darken-1">
							<v-icon size="16" color="grey darken-1">fas fa-chevron-double-left</v-icon>
							{{ prevItem['Name' + LanguageCodeSuffix] }}
						</span>
					</router-link>
				</v-col>
				<v-col class="text-right" v-if="nextItem">
					<router-link :to="`/TimeToChange/${ItemDetails.ChapterId}/${nextItem.ItemId}`">
						<span
							style="display:inline-block"
							class="navigationChapters mr-3 grey--text text--darken-1">
							{{ nextItem['Name' + LanguageCodeSuffix] }}
							<v-icon size="16" color="grey darken-1">fas fa-chevron-double-right</v-icon>
						</span>
					</router-link>
				</v-col>
			</v-row>
				<v-row v-if="LoadingItemDetails">
					<v-col cols="12" md="8">
						<div>
							<v-skeleton-loader type="image" class="rounded"></v-skeleton-loader>
							<v-skeleton-loader type="text, paragraph"></v-skeleton-loader>
						</div>
					</v-col>
					<v-col cols="12" md="4">
						<v-skeleton-loader type="image" class="rounded"
							style="display: inline-block;height:100px; width:100px"></v-skeleton-loader>
						<v-skeleton-loader class="mt-3" type="heading"></v-skeleton-loader>
						<v-skeleton-loader class="mt-3" type="paragraph"></v-skeleton-loader>
						<v-card v-for="i in 3" :key="i" class="mb-5">
							<v-skeleton-loader
								type="card-heading, list-item-three-line, actions"></v-skeleton-loader>
						</v-card>
					</v-col>
				</v-row>

			<v-row v-if="ItemDetails">
				<v-col cols="12" md="8">
					<div>
						<v-img
							class="rounded elevation-5"
							contain
							:src="ItemDetails.ImageUrl"></v-img>

						<h1 class="text-subtitle-2 font-weight-bold my-1" >{{ ItemDetails["Name" + LanguageCodeSuffix] }}</h1>

						<MultiLineContent class="photoDescription text-body-1" :InputText="ItemDetails['Content' + LanguageCodeSuffix]"></MultiLineContent>
					</div>
				</v-col>
				<v-col cols="12" md="4">
					<v-img
						class="rounded"
						height="80" width="80"
						:src="`https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/ChapterIcons${LanguageCodeSuffix}/Chapter${ItemDetails.ChapterId}.png`">
					</v-img>
					<h3 class="text-h5 mb-3">{{ $t("availableFormats") }}</h3>
					<ShopItemCard :ItemDetails="ItemDetails"></ShopItemCard>
					<DonationInfoDialog  v-model="showInfoDialog"></DonationInfoDialog>
					<NFFCartolina v-if="false" :ItemDetails="ItemDetails"></NFFCartolina>
					<NFFCard  v-if="ItemDetails.NFFLink" :ItemDetails="ItemDetails" />
					<NFFBookCard></NFFBookCard>

					<v-divider class="my-3"></v-divider>
					<div class="text-body-2">
						{{ $t("requestContact") }}
						<span class="font-weight-bold">produzione@sgpitalia.com</span>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import MultiLineContent from "@/components/Shared/Common/MultiLineContent.vue";
import DonationInfoDialog from "../components/DonationInfoDialog.vue";
import ShopItemCard from "./ShopItemCard.vue";
import NFFCard from "./NFFCard"
import NFFBookCard from "./NFFBookCard"
import NFFCartolina from "./NFFCartolina"

export default {
	components: { MultiLineContent, DonationInfoDialog, NFFCard, NFFBookCard, NFFCartolina, ShopItemCard },
	metaInfo() {
		return {
			title: this.chapter?.["Name" + this.LanguageCodeSuffix] + ": " + this.ItemDetails?.["Name" + this.LanguageCodeSuffix],
			meta: [{
				name: "description",
				content: this.chapter?.["Name" + this.LanguageCodeSuffix] + ": " + this.ItemDetails?.["Name" + this.LanguageCodeSuffix]
			}],
		};
	},
	data() {
		return {
			LoadingChapters: false,
			chapter: null,
			LoadingProject: false,
			ProjectDetails: null,
			LoadingItemDetails: false,
			ItemDetails: null,
			showInfoDialog: false,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}],
		}
	},
	computed: {
		...mapGetters([
			"isUserAuthenticated",
			"isUserInRole",
			"MainProject"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		ItemId() {
			return parseInt(this.$route.params.ItemId);
		},
		nextItem() {
			if (!this.chapter?.ShopItems) return null;
			const index = this.chapter?.ShopItems.findIndex(c => c.ItemId === this.ItemId);
			return (index + 1 === this.chapter?.ShopItems.length) ? null : this.chapter?.ShopItems[index + 1]
		},
		prevItem() {
			if (!this.chapter?.ShopItems) return null;
			const index = this.chapter?.ShopItems.findIndex(c => c.ItemId === this.ItemId);
			return (index === 0) ? null : this.chapter?.ShopItems[index - 1]
		},
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ShopItemsService = new CrudClient("Shop/Items");
		this.BillingProfileService = new CrudClient(
			"SGP/Client/BillingProfilesSelf"
		);
	},
	async mounted() {
		const sustainabilityProjecId = 1;
		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;

		await this.LoadItemDetails();
		await this.LoadChapterDetails(this.ItemDetails.ChapterId);

		this.breadcrumbsItems.push({
			text: "Time To Change",
			disabled: false,
			exact: true,
			to: "/TimeToChange",
		});

		if (this.$route.query.from === "gallery") {
			this.breadcrumbsItems.push({
				text: "Gallery",
				disabled: false,
				exact: true,
				to: "/TimeToChange/Gallery",
			});
		} else {
			this.breadcrumbsItems.push({
				text: this.chapter["Name" + this.LanguageCodeSuffix],
				disabled: false,
				exact: true,
				to: "/TimeToChange/" + this.chapter.ChapterId,
			});
		}
		this.breadcrumbsItems.push({
			text: this.ItemDetails["Name" + this.LanguageCodeSuffix],
			disabled: true,
			exact: true,
			to: "/TimeToChange/" + this.chapter.ChapterId + "/" + this.ItemDetails.ItemId,
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		async LoadItemDetails() {
			try {
				this.LoadingItemDetails = true;
				this.ItemDetails = await this.ShopItemsService.GetSelectedFields(this.$route.params.ItemId,
					`*, ShopItemVersions.*,
ShopItemVersions.ShopItemVersionGroup.*, 
ShopItemVersions.ShopItemVersionGroup.ShopItemVersionOptions.* 
				`);

				this.ItemDetails.ShopItemVersions = this.ItemDetails.ShopItemVersions
					.filter(version => version.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				this.ItemDetails.ShopItemVersions.forEach(element => {
					this.$set(element, "showDialog", false);
				});
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingItemDetails = false;
			}
		},

		async LoadChapterDetails(chapterId) {
			try {
				this.LoadingChapters = true;

				this.chapter = await this.ProjectChaptersService.GetSelectedFields(chapterId, "ChapterId, NameIT, NameEN, ShopItems.*");
				this.chapter.ShopItems = this.chapter.ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		}
	}
}
</script>
<style lang="scss">

</style>
<i18n>
	{
		"it":{
			"availableFormats": "Formati disponibili",
			"priceFrom": "Prezzo a partire da ",
			"requestContact": "Per qualsiasi richiesta di altro formato o finitura ci potete contattare alla mail",
			"maxAvailibility": "Solo {0} stampe disponibili",
			"printMaterial": "carta Giclée 100% cotone da 310 gsm"
		},
		"en":{
			"availableFormats": "Available formats",
			"priceFrom": "Price starting from ",
			"requestContact": "For any request for another format or finish, you can contact us at",
			"maxAvailibility": "Only {0} prints available",
			"printMaterial": "310gsm 100% cotton Giclée paper"
		}
	}
</i18n>
