<template>
	<canvas ref="canvas" id="canvas" ></canvas>
</template>
<script>
export default {
	props: {
		selectedPhoto: { type: String, required: true },
		printWidth: { type: Number },
		selectedFrame: { type: Number },
		passepartoutWidth: { type: Number },
		EmptyBackground: { type: Boolean }
	},
	data() {
		return {
		};
	},
	watch: {
		selectedPhoto: function (val) {
			if (val) { this.RenderPhoto(); }
		},
		printWidth: function (val) {
			if (val) { this.RenderPhoto(); }
		},
		selectedFrame: function (val) {
			this.RenderPhoto();
		},
		passepartoutWidth: function (val) {
			this.RenderPhoto();
		},
		EmptyBackground: function (val) {
			this.RenderPhoto();
		}
	},
	mounted() {
		this.RenderPhoto();
	},

	methods: {
		drawOuterShadow(ctx) {
			if (!this.EmptyBackground) {
				ctx.shadowColor = "rgba(0,0,0,0.4)";
				ctx.shadowBlur = 6;
				ctx.shadowOffsetX = -6;
				ctx.shadowOffsetY = 6;
			} else {
				ctx.shadowColor = "rgba(0,0,0,0.5)";
				ctx.shadowBlur = 12;
				ctx.shadowOffsetX = 16;
				ctx.shadowOffsetY = 16;
			}
		},

		drawInnerShadow(ctx) {
			ctx.shadowColor = "rgba(0,0,0,0.2)";
			ctx.shadowBlur = 2;
			ctx.shadowOffsetX = -2;
			ctx.shadowOffsetY = 2;
		},

		resetShadow(ctx) {
			ctx.shadowColor = "rgba(0,0,0,0)";
			ctx.shadowBlur = 0;
			ctx.shadowOffsetX = 0;
			ctx.shadowOffsetY = 0;
		},

		RenderPhoto() {
			const canvas = this.$refs.canvas;
			const ctx = canvas.getContext("2d");

			let cmToPixelsRatio;
			let passapartoutColor;

			const imgBackground = new Image();
			if (this.EmptyBackground) {
				imgBackground.src = "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Render/emptybackground.png";
				canvas.width = 800;
				canvas.height = 800;
				cmToPixelsRatio = 800.0 / 150.0;
				passapartoutColor = "#FFFCFB";
			} else {
				imgBackground.src = "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Render/interno2.jpg";
				canvas.width = 1024;
				canvas.height = 1024;
				cmToPixelsRatio = 500.0 / 150.0;
				passapartoutColor = "#F0EDEC";
			}

			const printSizeCm = this.printWidth;
			const frameWidthCm = 0.8;
			const frameWidthPixel = frameWidthCm * cmToPixelsRatio;
			const whiteAreaWidthPixel = this.passepartoutWidth * cmToPixelsRatio;
			// proportions 500px -> 150cm
			// 1 cm
			imgBackground.onload = () => {
				// drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
				ctx.drawImage(imgBackground,
					0, 0, canvas.width, canvas.height,
					0, 0, imgBackground.width, imgBackground.height,
				);

				const imgPhoto = new Image();
				this.$log.debug(this.selectedPhoto);
				imgPhoto.src = this.selectedPhoto;

				const targetWidth = cmToPixelsRatio * printSizeCm;
				const targetHeight = targetWidth / imgPhoto.width * imgPhoto.height;

				const photoStartPositionX = (canvas.width - targetWidth) / 2;
				const photoStartPositionY = 350 - targetHeight / 2;

				// ctx.shadowColor = "rgba(0,0,0,0)";

				imgPhoto.onload = () => {
					if (this.selectedFrame) {
					// Once the image is loaded, call the drawImage function
					// 70x100, long size fix it to 100cm
						const totalFrameExt = frameWidthPixel + whiteAreaWidthPixel;

						this.drawOuterShadow(ctx);

						ctx.fillStyle = this.selectedFrame === 1 ? "#0A0A08" : "#DBDBDB";
						ctx.fillRect(photoStartPositionX - totalFrameExt, photoStartPositionY - totalFrameExt,
							targetWidth + 2 * totalFrameExt, targetHeight + 2 * totalFrameExt);

						this.resetShadow(ctx);
						ctx.fillStyle = passapartoutColor;
						ctx.fillRect(photoStartPositionX - whiteAreaWidthPixel, photoStartPositionY - whiteAreaWidthPixel,
							targetWidth + 2 * whiteAreaWidthPixel, targetHeight + 2 * whiteAreaWidthPixel);

						this.drawInnerShadow(ctx)
						ctx.drawImage(imgPhoto, photoStartPositionX, photoStartPositionY, targetWidth, targetHeight);
					} else {
						this.drawOuterShadow(ctx);
						ctx.fillStyle = passapartoutColor;
						ctx.fillRect(photoStartPositionX - whiteAreaWidthPixel, photoStartPositionY - whiteAreaWidthPixel,
							targetWidth + 2 * whiteAreaWidthPixel, targetHeight + 2 * whiteAreaWidthPixel);

						this.drawInnerShadow(ctx)
						ctx.drawImage(imgPhoto, photoStartPositionX, photoStartPositionY, targetWidth, targetHeight);
					}
				};
			}
		}
	}
}
</script>

<style>

</style>
