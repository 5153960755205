<template>
	<div>
		<v-container fluid>
				<v-row>
					<v-col cols="6" md="3" class="text-center pa-5" v-for="item in iconSection" :key="item.icon">
						<v-icon size="50">{{ item.icon }}</v-icon>
						<h4 class="my-4">{{ item.title }}</h4>
						<p>
							{{ item.text }}
						</p>
						<p>
						<v-btn v-if="item.link" :to="item.link" text color="primary">{{ item.linkText }}</v-btn>
						</p>
					</v-col>
				</v-row>
			</v-container>
	</div>
</template>
<script>
export default {
	watch: {
		"$root.$i18n.locale": function (val) {
			this.$log.debug("locale: " + val);
			this.SetItemText();
		}
	},
	mounted() {
		this.SetItemText();
	},
	methods: {
		SetItemText() {
			this.iconSection = [
				{
					icon: "fab fa-paypal",
					title: this.$t("securePaymentTitle"),
					text: this.$t("securePaymentText")
				},
				{
					icon: "far fa-user-headset",
					title: this.$t("assistanceTitle"),
					text: this.$t("assistanceText"),
					link: "/Support",
					linkText: this.$t("common.contactUs"),
				},
				{
					icon: "fas fa-truck",
					title: this.$t("shippingTitle"),
					text: this.$t("shippingText"),
					// link: "/Support",
					// linkText: "Contattaci"
				},
				{
					icon: "far fa-file-certificate",
					title: this.$t("certificateTitle"),
					text: this.$t("certificateText"),
					// link: "/Support",
					// linkText: "Contattaci"
				}
			];
		}
	},

	data() {
		return {
			iconSection: null
		};
	}
}
</script>
<i18n>
{
	"it":{
		"securePaymentText": "Pagamenti sicuri con PayPal",
		"securePaymentTitle": "Pagamenti sicuri",
		"assistanceTitle": "Assistenza",
		"assistanceText": "Puoi contattarci dal lunedi al venerdi dalle 9h alle 19h o via email",
		"shippingTitle": "Spedizioni",
		"shippingText": "Spedizioni sicure con corriere espresso in Italia",
		"certificateTitle": "Authenticità certificata",
		"certificateText": "Edizione limitata con certificazione"
	},
	"en":{
		"securePaymentText": "Secure payments with PayPal",
		"securePaymentTitle": "Secure payment",
		"assistanceTitle": "Need help?",
		"assistanceText": "You can contact us via e-mail",
		"shippingTitle": "Safe shippings",
		"shippingText": "Shipping to Italy with special packaging",
		"certificateTitle": "Authentic certified artwork",
		"certificateText": "Limited number of prints with certificate"
	}
}
</i18n>
