<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<v-icon slot="divider" class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
		</v-breadcrumbs>

		<v-container fluid fill-height >
			<v-row 	v-if="LoadingChapters">
				<v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
				<v-col></v-col>
				<v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
			</v-row>
			<v-row v-if="chaptersList"  justify="center">
				<v-col v-if="prevChapter">
					<router-link :to="'/TimeToChange/' + prevChapter.ChapterId">
						<span
							style="display:inline-block"
							class="navigationChapters mr-3 grey--text text--darken-1">
							<v-icon size="16" color="grey darken-1">fas fa-chevron-double-left</v-icon>
							{{ $t("objective") }} {{ prevChapter.ChapterId }}: {{ prevChapter['Name' + LanguageCodeSuffix] }}
						</span>
					</router-link>
				</v-col>
				<v-col class="text-right" v-if="nextChapter">
					<router-link :to="'/TimeToChange/' + nextChapter.ChapterId">
						<span
							style="display:inline-block"
							class="navigationChapters mr-3 grey--text text--darken-1">
							{{  $t("objective") }} {{ nextChapter.ChapterId }}: {{ nextChapter['Name' + LanguageCodeSuffix] }}
							<v-icon size="16" color="grey darken-1">fas fa-chevron-double-right</v-icon>
						</span>
					</router-link>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" order="2" md="8" order-md="1" class="pt-0">
					<div v-if="0">
						<PhotoGrid
								v-if="LoadingChapters"
								:Files="[{ blob: { Metadata: { AspectRatio: 1.2}} },
							{ blob: { Metadata: { AspectRatio: 1.3 } } },
							{ blob: { Metadata: { AspectRatio: 0.8 } } },
							{ blob: { Metadata: { AspectRatio: 1.2 } } },
							{ blob: { Metadata: { AspectRatio: 1.2 } } }]"
								class="mt-5"
								:targetHeight="$vuetify.breakpoint.xs ? 250 : 400"
							>
								<template v-slot:cell="">
										<v-skeleton-loader type="image"></v-skeleton-loader>
									<div class="imageCaption my-3 text-center">
										<v-skeleton-loader  style="display: inline-block; width:200px" type="text"></v-skeleton-loader>
									</div>
								</template>

						</PhotoGrid>
						<PhotoGrid
							v-if="chapter && chapter.ShopItems && blobs && !LoadingChapters"
							:Files="chapter.ShopItems"
							class="mt-5"
							:targetHeight="$vuetify.breakpoint.xs ? 250 : 400"
						>
							<template v-slot:cell="{ file }">
								<router-link :to="'/TimeToChange/' + chapter.ChapterId + '/' + file.ItemId">
									<AzurePreviewImage
									class="elevation-5"
										:file="file.blob"
									>
									</AzurePreviewImage>
								</router-link>
								<div class="imageCaption my-3 text-center">
									<!-- {{ file["Content" + LanguageCodeSuffix] }} -->
									<MultiLineContent class="photoDescription font-italic text-body-2" :InputText="file['Content' + LanguageCodeSuffix]"></MultiLineContent>
								</div>
							</template>

						</PhotoGrid>
					</div>
					<div v-else>
						<v-container fluid>
							<v-row v-if="LoadingChapters">
								<v-col cols="12" md="6" lg="4" v-for="i in 6" :key="i">
									<v-skeleton-loader type="image"></v-skeleton-loader>
									<div class="imageCaption my-3 text-center">
										<v-skeleton-loader  style="display: inline-block; width:200px" type="text"></v-skeleton-loader>
									</div>
								</v-col>
							</v-row>
							<v-row v-if="chapter && chapter.ShopItems && blobs && !LoadingChapters">
								<v-col cols="12" md="6" lg="4" v-for="file in chapter.ShopItems" :key="file.ItemId">
									<router-link :to="'/TimeToChange/' + chapter.ChapterId + '/' + file.ItemId">
										<AzurePreviewImage
										class="elevation-5"
											:file="file.blob"
										>
										</AzurePreviewImage>
									</router-link>
									<div class="imageCaption my-3 text-center">
										<!-- {{ file["Content" + LanguageCodeSuffix] }} -->
										<MultiLineContent class="photoDescription font-italic text-body-2" :InputText="file['Content' + LanguageCodeSuffix]"></MultiLineContent>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</div>
				</v-col>
				<v-col cols="12" order="1" md="4" order-md="2"  class="pt-5">
					<div v-if="LoadingChapters">
						<v-skeleton-loader class="rounder" height="100" width="100" type="image"></v-skeleton-loader>
						<v-skeleton-loader class="my-5" type="heading"></v-skeleton-loader>

						<v-skeleton-loader class="my-5" type="paragraph,paragraph,paragraph,paragraph"></v-skeleton-loader>
					</div>
					<div v-if="!LoadingChapters && chapter">
						<div>
							<v-img class="rounded"
								height="100" width="100"
								:src="`https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/ChapterIcons${LanguageCodeSuffix}/Chapter${ChapterId}.png`"></v-img>
						</div>
						<h1>{{ chapter["Name" + LanguageCodeSuffix] }}</h1>
						<div class="my-5" v-if="LanguageCodeSuffix === 'IT'">
							<p v-for="part in ContentPartsIT" :key="part">
								{{ part }}
							</p>
						</div>
						<div class="my-5" v-if="LanguageCodeSuffix === 'EN'">
							<p v-for="part in ContentPartsEN" :key="part">
								{{ part }}
							</p>
						</div>
						<div v-if="chapter.VimeoVideoUrl">
							<h3 class="sgpshop-h3">{{ $t("seeBackstage")}}</h3>
							<v-responsive aspect-ratio="1.5">
								<vueVimeoPlayer class="vimeo" :video-url="chapter.VimeoVideoUrl"></vueVimeoPlayer>
							</v-responsive>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import MultiLineContent from "@/components/Shared/Common/MultiLineContent.vue";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage";
import { vueVimeoPlayer } from "vue-vimeo-player";
export default {
	metaInfo() {
		return {
			title: this.chapter?.["Name" + this.LanguageCodeSuffix],
			meta: [{ name: "description", content: `Time To Change: ${this.chapter?.["Name" + this.LanguageCodeSuffix]}` }],
		};
	},
	name: "ChapterView",
	components: {
		PhotoGrid,
		AzurePreviewImage,
		MultiLineContent,
		vueVimeoPlayer
	},
	data() {
		return {
			LoadingChapters: false,
			chapter: null,
			blobs: null,
			LoadingProject: false,
			ProjectDetails: null,
			chaptersList: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Time To Change",
					disabled: false,
					exact: true,
					to: "/TimeToChange",
				}]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		ContentPartsIT() {
			if (!this.chapter) return null;
			return this.chapter.ContentIT.split(/\r?\n/).filter((i) => i);
		},
		ContentPartsEN() {
			if (!this.chapter) return null;
			return this.chapter.ContentEN.split(/\r?\n/).filter((i) => i);
		},
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		ChapterId() {
			return parseInt(this.$route.params.ChapterId);
		},

		nextChapter() {
			if (!this.chaptersList) return null;
			const index = this.chaptersList.findIndex(c => c.ChapterId === this.ChapterId);
			return (index + 1 === this.chaptersList.length) ? null : this.chaptersList[index + 1]
		},
		prevChapter() {
			if (!this.chaptersList) return null;
			const index = this.chaptersList.findIndex(c => c.ChapterId === this.ChapterId);
			this.$log.debug(index);
			return (index === 0) ? null : this.chaptersList[index - 1]
		},
	},
	created() {
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		const sustainabilityProjecId = 1;

		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;

		await this.LoadChapterDetails(this.ChapterId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),
		async LoadChapterDetails(chapterId) {
			try {
				this.LoadingChapters = true;

				this.chapter = await this.ProjectChaptersService.GetSelectedFields(chapterId, "*, ShopItems.*");
				this.blobs = await this.ProjectChaptersService.Get(this.ChapterId, "Blobs");

				this.chapter.ShopItems = this.chapter.ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);

				for (let i = 0; i < this.chapter.ShopItems.length; i++) {
					this.chapter.ShopItems[i].blob = this.blobs.find(b => this.chapter.ShopItems[i].ImagePath.endsWith(b.DisplayName));
				}

				this.breadcrumbsItems.push({
					text: this.chapter["Name" + this.LanguageCodeSuffix],
					disabled: true,
					exact: true,
					to: "/TimeToChange/" + this.ChapterId,
				});

				const projectId = 1;

				const res = await this.ProjectChaptersService.GetPaged({
					limit: 0,
					fields: "ChapterId, NameIT, NameEN, SortOrder",
					orderBy: "SortOrder",
					urlPostFix: `Project/${projectId}`
				});

				this.chaptersList = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		}

	}
}
</script>
<style lang="scss">
.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.imageCaption{
	font-family: $font_Main;
	// font-weight: 400;
	height:60px;
	overflow: hidden;
	// font-style: italic;
	// text-align: center;
	height:80px;
	width:100%;
}
.navigationChapters{
	font-size:16px;
	font-weight: bold;
}
</style>
<i18n>
{
	"it": {
		"objective": "Obiettivo",
		"seeBackstage": "Vedi Backstage"
	},
	"en": {
		"objective": "Objective",
		"seeBackstage": "See Backstage"
	}
}
</i18n>
