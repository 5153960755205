<template>
	<v-img
		:src="file.Metadata.PreviewFile"
		:alt="file.DisplayName"
		:aspect-ratio="file.Metadata.AspectRatio"
		class="grey darken-4 rounded"
		@click="$emit('click')"
	>
		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular
					indeterminate
					color="grey lighten-2"
				></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>
<style lang="scss" scoped>
.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}

.ImageDescription {
	position: absolute;
	bottom: 5px;
	left: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	// white-space: nowrap;
	// overflow: hidden;
	// text-overflow: ellipsis;
	max-width: 90%;
}

.FaceRecognitionIcon {
	position: absolute;
	top: 5px;
	right: 5px;

	max-width: 90%;
	background-color: black;
	padding: 5px 5px;
	border-radius: 4px;
	opacity: 0.9;
}

.infoText {
	background-color: black;
	color: white;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	font-size: 16px;
}
</style>
<script>

export default {
	computed: {
	},
	props: {
		file: {
			type: Object,
		},
		showArtist: {
			type: Boolean,
			default: false,
		},
		showImageDescription: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
