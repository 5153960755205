<template>
	<v-dialog :value="value"
			@input="$emit('input', $event)"
			max-width="500px"
			@keydown.esc="$emit('input', false)">
<v-card>
	<v-card-text>
		<v-container fluid>
			<v-row>
				<v-col cols="6" class="ma-0 pa-0">
					<h1 class="dialogTitle">Foto {{ ItemDetails.NameIT }} </h1>
					<div class="dialogSubtitle">
						{{ $t('sgpshop.format') }} {{ Version.NameIT }}
						<!-- <br />
						Prezzo: {{ Version.Price | formatCurrency }} -->
					</div>
					<!-- <div style="font-size:1rem">
						{{ $t("dialogTitle") }}
					</div> -->
				</v-col>
					<v-col cols="6">
					<v-img
						class="rounded elevation-5"
						max-height="300" contain
						:src="ItemDetails.ImagePreviewUrl">
					</v-img>
				</v-col>
			</v-row>
		</v-container>
	</v-card-text>
	<v-card-text v-if="Version.GroupId">
		<h3 class="optionGroupLabel">{{ Version.ShopItemVersionGroup["Name" + LanguageCodeSuffix] }}</h3>
		<v-radio-group v-model="selectedOptionId" class="mt-1">
			<v-radio v-for="option in Version.ShopItemVersionGroup.ShopItemVersionOptions"
			:key="option.OptionId"
			:value="option.OptionId"
			class="mb-3">
				<template v-slot:label>
					<v-container fluid class="mb-3 pa-2 rounded"
							:class="{ 'grey lighten-2': option.OptionId === selectedOptionId }">
						<v-row>
							<v-col cols="8"> {{ option["Name" + LanguageCodeSuffix] }}</v-col>
							<v-col cols="4" class="font-weight-bold"> {{ Version.Price + option.Price | formatCurrency }}</v-col>
						</v-row>
						<v-row>
							<v-col cols="8">{{ $t("sgpshop.deliveryPriceLabel") }}:</v-col>
							<v-col cols="4"><span v-if="option.DeliveryPrice" class="font-weight-bold">+{{ option.DeliveryPrice | formatCurrency }}</span>
								<span v-else>
										GRATUITA
								</span></v-col>
						</v-row>
					</v-container>
				</template>
				</v-radio>
		</v-radio-group>
	</v-card-text>
	<v-card-actions>
		<v-spacer></v-spacer>
		<v-btn @click="addItemDialog" :disabled="Version.GroupId && !selectedOptionId" large color="primary"><v-icon left>fas fa-cart-plus</v-icon>{{ $t("sgpshop.addToBasket") }}</v-btn>
	</v-card-actions>
</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "ItemDetailsDialog",
	$_veeValidate: { validator: "ItemDetailsDialog" },
	props: {
		value: { type: Boolean, required: true },
		ItemDetails: { type: Object },
		Version: { type: Object },
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	data() {
		return {
			selectedOptionId: null,
			selectedOptionPrice: null
		}
	},
	watch: {
		value: async function (val) {
			if (!val) {
				this.selectedOptionId = null;
				this.selectedOptionPrice = null;
			}
		},
		ItemDetails: {
			immediate: true,
			handler: async function (val) {
				this.$log.debug("watch ItemDetails");
			}
		},
		Version: {
			immediate: true,
			handler: async function (val) {
				this.$log.debug("watch Version");
			}
		},
	},
	created() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "AddItemToBasket"]),
		addItemDialog() {
			const selectedOption = this.Version?.ShopItemVersionGroup?.ShopItemVersionOptions?.find(o => o.OptionId === this.selectedOptionId);
			const basketItem = {
				item: {
					item: this.ItemDetails,
					version: this.Version,
					selectedOption: {
						OptionId: this.selectedOptionId,
						Price: selectedOption?.Price ?? 0,
						NameIT: selectedOption?.NameIT,
						NameEN: selectedOption?.NameEN,
						DeliveryPrice: selectedOption?.DeliveryPrice ?? 0
					}
				},
				quantity: 1
			};
			this.$log.debug(basketItem);

			this.AddItemToBasket(basketItem);

			this.$emit("input", false);
			this.snackSuccess({ Text: this.$t("itemAddedToBasket") });
		}

	}
}
</script>
<i18n>
	{
		"it":{
			"dialogTitle": "Personalizza la tua stampa",
			"itemAddedToBasket": "Hai aggiunto il prodotto nel tuo carrello!"
		},
		"en":{
			"dialogTitle": "Personalize your print",
			"itemAddedToBasket": "You've successfully added this item to your shopping cart!"
		}
	}
</i18n>
<style lang="scss" scoped>
.dialogTitle{
	font-size: 1.6rem;
	font-weight:bold;
	margin:15px 0px;
}
.dialogSubtitle{
	font-size: 1.2rem;

}
.optionsTitle{
font-size: 1.2rem;
}
.optionGroupLabel{
	font-size: 1.2rem;
}
</style>
