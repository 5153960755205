<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
					<v-icon slot="divider" class="secondary--text text--lighten-2"
						>fas fa-caret-right</v-icon
					>
		</v-breadcrumbs>
		<h1 class="sgpshop-h1">{{ $t("sgpshop.yourBasket") }}</h1>

		<v-card>
			<v-card-text>
				<v-container fluid fill-height v-if="Basket.length > 0">
					<v-row dense class="basketBottomBorder" v-if="$vuetify.breakpoint.mdAndUp">
							<v-col cols="3" class="basketHeader">{{ $t("sgpshop.artPiece") }}</v-col>
							<v-col cols="3" class="basketHeader">{{ $t("sgpshop.format") }}</v-col>
							<v-col cols="3" class="basketHeader">{{ $t("common.quantity") }}</v-col>
							<v-col cols="3" class="basketHeader text-right">{{ $t("common.total") }}</v-col>
					</v-row>
					<v-row  v-for="(basketItem, index) in Basket" :key="index" class="basketBottomBorder" >
						<v-col cols="12" md="3">
							<v-img
								class="rounded elevation-5"
								max-width="400" contain
								:src="basketItem.item.ImagePreviewUrl"></v-img>

						</v-col>
						<v-col cols="12" md="3">
							<h4>{{ basketItem.item['Name' + LanguageCodeSuffix] }}</h4>
								<span v-if="basketItem.version.GroupId !== 3">{{ $t("sgpshop.format") }}:</span> {{ basketItem.version['Name' + LanguageCodeSuffix] }}
								<!-- <span v-if="basketItem.version.Height">- {{ basketItem.version.Height }} x {{ basketItem.version.Width }} cm</span> -->
								- <span>{{ basketItem.version.Price | formatCurrency }}</span>
							<div v-if="basketItem.version.GroupId !== 3">
								{{  $t("sgpshop.finish") }}: {{ basketItem.selectedOption['Name' + LanguageCodeSuffix] }}
								<span v-if="basketItem.selectedOption.Price > 0">- {{ basketItem.selectedOption.Price | formatCurrency }}</span>
							</div>
						</v-col>
						<v-col cols="12" md="3">
							<v-select :value="basketItem.quantity" :label="$t('common.quantity')"
							@input="$set(basketItem, 'quantity', $event)"
							:items="Array.from({ length: basketItem.version.MaxAvailability ? basketItem.version.RemainingAvailability : 10 }, (v, i) => i+1)">
						</v-select>

							<br />
							<v-btn text small @click="removeItem(index)"><v-icon left>fas fa-times</v-icon>{{ $t("common.remove") }}</v-btn>
						</v-col>
						<v-col cols="12" md="3" class="text-right"><span class="basketSubtotal">
							{{ $t("sgpshop.itemsTotal") }}

							{{ (basketItem.quantity * (basketItem.version.Price + basketItem.selectedOption.Price )) | formatCurrency}}</span>
						</v-col>
					</v-row>

					<v-row class="basketBottomBorder">
						<v-col cols="12" md="6" offset-md="6" class="text-right"><span class="basketSubtotal ">{{ $t("sgpshop.deliveryPriceLabel") }}: {{ DeliveryPriceTotal | formatCurrency }}</span></v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6" offset-md="6" class="text-right">
							<span class="basketPrice ">{{$t("common.total")}}: {{ BasketItemsTotal + DeliveryPriceTotal | formatCurrency }}</span>
							<br />
								<span>{{ $t("ivaInfo") }}  {{ (BasketItemsTotal + DeliveryPriceTotal) / 1.22 * 0.22 | formatCurrency }}
								</span>
						</v-col>
					</v-row>
				</v-container>
				<v-alert
					icon="fas fa-shopping-basket"
					color="grey lighten-1" v-if="Basket.length === 0">
					<div class="font-weight-bold text-center">
					{{ $t("EmptyBasket") }}
					</div>
				</v-alert>
				<v-alert color="grey lighten-2" icon="fas fa-calendar-alt" v-if="Basket.length > 0">
					<div class="text-center">
						{{ $t("DeliveryTimes") }}
						<br />
						{{ $t("SupportMail") }}
						<a href="mailto:produzione@sgpitalia.com">produzione@sgpitalia.com</a>
					</div>
				</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-btn large block color="primary" v-if="isUserAuthenticated && isUserInRole('ShopClient')"
				to="/ShopClients/CompleteOrder" :disabled="Basket.length === 0">{{ $t("sgpshop.completeOrder") }}</v-btn>

				<v-btn large block color="primary" v-else
					to="/CheckoutRegister" :disabled="Basket.length === 0">{{ $t("sgpshop.completeOrder") }}</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters([
			"Basket",
			"BasketItemsTotal",
			"DeliveryPriceTotal",
			"isUserAuthenticated",
			"isUserInRole"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.basket"),
					disabled: true,
					exact: true,
					to: "/Basket",
				}]
		};
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "AddItemToBasket"]),

		removeItem(index) {
			this.Basket.splice(index, 1);
		}
	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Il tuo carrello",
			"completeOrder": "Procedi all'ordine",
			"EmptyBasket": "Il tuo carrello è vuoto",
			"ivaInfo": "Di cui IVA 22%",
			"DeliveryTimes": "Realizzazione e spedizione in 15 giorni lavorativi.",
			"SupportMail": "In caso di particolare urgenza contattare"
		},
		"en":{
			"pageTitle": "Your basket",
			"completeOrder": "Complete your order",
			"EmptyBasket": "Your basket is empty",
			"ivaInfo": "Including 22% VAT",
			"DeliveryTimes": "Production and shipping in 15 working days.",
			"SupportMail": "For particular requeste please contact"
		}
	}
</i18n>
