<template>
  <div v-if="chapter" class="mb-10">
	<div v-if="LoadingChapters">
		<v-skeleton-loader type="heading" width="330"></v-skeleton-loader>
	</div>
	<div v-else>
		<router-link class="NoDecoration" :to="`/${ProjectDetails.FriendlyUrl}/${chapter.ChapterId}`">
			<h3 class="sgpshop-h3 text-center black--text">{{ chapter["Name" + LanguageCodeSuffix] }}</h3>
		</router-link>
	</div>
	<PhotoGrid
			v-if="LoadingChapters"
			:Files="[{ blob: { Metadata: { AspectRatio: 1.2 } } },
			{ blob: { Metadata: { AspectRatio: 1.3 } } },
			{ blob: { Metadata: { AspectRatio: 0.8 } } },
			{ blob: { Metadata: { AspectRatio: 1.2 } } },
			{ blob: { Metadata: { AspectRatio: 1.2 } } }]"
			class="mt-5"
			:targetHeight="$vuetify.breakpoint.xs ? 250 : 400"
		>
			<template v-slot:cell="">
				<v-skeleton-loader type="image" :height="$vuetify.breakpoint.xs ? 250 : 400"></v-skeleton-loader>
			</template>

	</PhotoGrid>
	<PhotoGrid
		v-if="chapter.ShopItems && blobs && !LoadingChapters"
		:Files="chapter.ShopItems"
		class="mt-5"
		:targetHeight="$vuetify.breakpoint.xs ? 100 : 220"
	>
		<template v-slot:cell="{ file }">
			<router-link :to="`/${ProjectDetails.FriendlyUrl}/${chapter.ChapterId}/${file.ItemId}?from=gallery`">
				<AzurePreviewImage
				class="elevation-5"
					:file="file.blob"
				>
				</AzurePreviewImage>
				</router-link>
		</template>

	</PhotoGrid>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid";

import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage";
export default {
	components: {
		PhotoGrid,
		AzurePreviewImage
	},
	data() {
		return {
			LoadingChapters: false,
			chapter: null,
			blobs: null,
			LoadingProject: false,
		}
	},
	props: {
		ProjectDetails: {
			required: true
		},
		ChapterId: {
			required: true
		}
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		await this.LoadChapterDetails(this.ChapterId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadChapterDetails(chapterId) {
			try {
				this.LoadingChapters = true;

				this.chapter = await this.ProjectChaptersService.GetSelectedFields(chapterId, "*, ShopItems.*");
				this.blobs = await this.ProjectChaptersService.Get(this.ChapterId, "Blobs");

				this.chapter.ShopItems = this.chapter.ShopItems.filter(item => item.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);

				for (let i = 0; i < this.chapter.ShopItems.length; i++) {
					this.chapter.ShopItems[i].blob = this.blobs.find(b => this.chapter.ShopItems[i].ImagePath.endsWith(b.DisplayName));
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		}

	}
}
</script>
