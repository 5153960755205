<template>
	<v-card class="mb-5">
		<v-card-title>
			{{ $t("title") }}
			<v-spacer></v-spacer>
			<v-btn text color="info" @click="showNffDialog = true">
				<v-icon left>fas fa-question-circle</v-icon>
				{{ $t("whatIsNFF") }}
			</v-btn>
		</v-card-title>
		<v-card-text>
			<div>
				{{ $t("productDescription") }}
			</div>
			<NffInfoDialog v-model="showNffDialog"> </NffInfoDialog>
			<div class="text-subtitle-1 font-weight-bold">
					{{ $t("donationAmount") }}
			</div>
			<AddEditBillingProfile
				v-model="showAddBillingProfile"
				v-if="isUserAuthenticated" @updated="OnBillingProfileUpdated"
				:hideCancelButton="true" :suppressSuccessMessage="true" :mode="1">
			</AddEditBillingProfile>
		</v-card-text>
		<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn large color="primary" @click="RedirectToDownload()"
					>
					{{ $t("common.continue") }}</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import NffInfoDialog from "./NFFInfoDialog";
import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile"
import CrudClient from "@/services/CrudClient/";

export default {
	components: { NffInfoDialog, AddEditBillingProfile },
	data() {
		return {
			showAddBillingProfile: false,
			showNffDialog: false,
			showInfoDialog: false,
			bookNffUrl: "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/NFF/Time%20To%20Change%20-%20digital%20book.pdf"
		}
	},
	computed: {
		...mapGetters([
			"isUserAuthenticated",
			"isUserInRole"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
	},
	created() {
		this.BillingProfileService = new CrudClient(
			"SGP/Client/BillingProfilesSelf"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async RedirectToDownload() {
			if (!this.isUserAuthenticated) {
				this.$router.push("/NFFCheckoutRegister");
				return;
			}

			// isUserInRole
			if (!this.isUserInRole("ShopClient")) {
				this.$router.push("/NFFCheckoutRegister");
				return;
			}

			const res = await this.BillingProfileService.GetPaged({
				limit: 0,
				fields: "*, ItalianCity.Name, Country.DescrizioneNazione",
				filter: "IsActive:true"
			});

			// if no billing Profile open dialog
			if (res.Data.length === 0) {
				this.showAddBillingProfile = true;
				return;
			}
			this.openInNewTab(this.bookNffUrl)
		},
		OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;
			this.openInNewTab(this.bookNffUrl)
		},
		openInNewTab(url) {
			window.open(url, "_blank", "noreferrer");
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"productDescription": "Acquistando il libro digitale  riceverai un file pdf che potrai consultare sull’applicazione Filegrant.",
			"whatIsNFF": "Cos’è un NFF",
			"title": "Libro digitale NFF  “Time to change”",
			"donationAmount": "Prezzo €35,50",
			"donationInfo": "L’intero ricavato verrà devoluto alla Fondazione per sostenere i progetti Aula 162 e ESIR."
		},
		"en": {
			"productDescription": "By purchasing the digital book you will receive a pdf file that you can consult on the Filegrant application.",
			"whatIsNFF": "What's an NFF",
			"title": "NFF digital book “Time to change”",
			"donationAmount": "Price €35.50",
			"donationInfo": "The entire proceeds will be donated to the Foundation to support the Aula 162 and ESIR projects."
		}
	}

</i18n>
