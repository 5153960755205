<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<v-icon slot="divider" class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
		</v-breadcrumbs>
		<h1 v-if="ProjectDetails" class="sgpshop-h1 text-uppercase">{{ ProjectDetails["Name" + LanguageCodeSuffix] }} Gallery</h1>
		<div v-for="chapter in ProjectChapters" :key="chapter.ChapterId">
			<GalleryChapter :ChapterId="chapter.ChapterId" :ProjectDetails="ProjectDetails"></GalleryChapter>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import GalleryChapter from "./GalleryChapter";

export default {
	metaInfo() {
		return {
			title: this.ProjectDetails?.NameIT + " Gallery",
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "ProjectGallery",
	components: {
		GalleryChapter
	},
	data() {
		return {
			LoadingChapters: false,
			ProjectChapters: null,
			ChapterBlobs: [],
			LoadingProject: false,
			ProjectDetails: null,
			breadcrumbsItems: [
				// {
				// 	text: "Home",
				// 	disabled: false,
				// 	exact: true,
				// 	to: "/",
				// }
			]
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		let projectId;
		if (this.$route.params.ProjectFriendlyUrl.toLowerCase() === "stefanoguindani") {
			projectId = 2;
			await this.SetProject(projectId);
			this.ProjectDetails = this.MainProject;
		}
		await this.SetBreadcrumbs();
		await this.LoadProjectChapters(projectId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),

		async SetBreadcrumbs() {
			this.breadcrumbsItems.push({
				text: this.ProjectDetails.NameIT,
				disabled: false,
				exact: true,
				to: `/${this.ProjectDetails.FriendlyUrl}`,
			});

			this.breadcrumbsItems.push({
				text: "Gallery",
				disabled: true,
				exact: true,
				to: `/${this.ProjectDetails.FriendlyUrl}/Gallery`,
			});
		},

		async LoadProjectChapters(projectId) {
			try {
				// this.LoadingChapters = true;
				const res = await this.ProjectChaptersService.GetPaged({
					limit: 0,
					fields: "ChapterId",
					orderBy: "SortOrder",
					urlPostFix: `Project/${projectId}`
				});

				this.ProjectChapters = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				// this.LoadingChapters = false;
			}
		},

	}
}
</script>
<i18n>
	{
		"it":{
			"description": "Visualizza tutte le foto del progetto Time To Change."
		},
		"en":{
			"description": "See all the photos of the Time To Change project."
		}
	}
</i18n>
