<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="$emit('input', false)"
		persistent
		no-click-animation
	>
		<v-toolbar class="primary white--text">
			<span v-if="mode === 1">{{ $t("NewBillingAddress") }}</span>
			<span v-else>{{ $t("UpdateBillingAddress") }}</span><v-spacer></v-spacer>
					<v-btn icon dark @click="$emit('input', false)">
						<v-icon>close</v-icon>
					</v-btn>
		</v-toolbar>
		<AddEditBillingProfileCard :mode="mode"
		ref="card"
			:BillingProfileId="BillingProfileId"
			:UserId="UserId"
			@updated="$emit('updated', $event)"
			@cancel="$emit('input', false)">
		</AddEditBillingProfileCard>
	</v-dialog>
</template>

<script>
import AddEditBillingProfileCard from "./AddEditBillingProfileCard"
import { mapActions } from "vuex";

export default {
	name: "BillingInfoForm",
	components: { AddEditBillingProfileCard },
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		BillingProfileId: { type: Number },
		UserId: { type: String },
	},
	data() {
		return {
			errorMessage: null,
			FormLoading: false,
		};
	},
	watch: {
		value: async function (val) {
			if (!val) this.$refs.card.ClearForm();
		}
	},
	created() {
	},
	async mounted() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"])
	},
};
</script>
<i18n>
{
	"it": {
		"NewBillingAddress": "Nuovo profilo di fatturazione",
		"UpdateBillingAddress": "Aggiorna il tuo profilo di fatturazione",
		"addBillingProfile_Header": "Dati di Fatturazione",
		"NotActive": "Non attivo"
	},
	"en": {
		"NewBillingAddress": "Add billing information",
		"UpdateBillingAddress": "Update billing information",
		"addBillingProfile_Header": "Billing Information",
		"NotActive": "Not active"
	}
}
</i18n>
