<template>
	<div>
		<p v-for="part in InputTextLines" :key="part">
			{{ part }}
		</p>
	</div>
</template>
<script>
export default {
	props: {
		InputText: {
			required: true
		}
	},
	computed: {
		InputTextLines() {
			if (!this.InputText) return null;
			return this.InputText.split(/\r?\n/).filter((i) => i);
		}
	}
}
</script>
