<template>
  <v-app id="app">
    <custom-snack-bar ref="snackBar" @snackBarClosed="snackBarClosed"></custom-snack-bar>
    <sidebar-menu ref="sidebar" initialDrawerOpen="true"></sidebar-menu>

    <v-app-bar app>
      <v-app-bar-nav-icon icon @click="ToggleSidebar"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link :to="Logo.To">
          <v-img :src="Logo.LogoSrc"
			contain :height="Logo.Height" :width="Logo.Width"></v-img></router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
		<v-btn to="/Basket" :color="(BasketItemsCount > 0) ? 'primary': null"><v-icon left>far fa-shopping-cart</v-icon>{{ BasketItemsCount }}</v-btn>
		<!-- <v-btn ><v-icon left>fas fa-shopping-cart</v-icon>test</v-btn> -->
		<!-- <v-btn>test</v-btn> -->
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" ><v-icon left>fas fa-globe</v-icon> {{ $i18n.locale }}</v-btn>
			</template>
			<v-list>
				<v-list-item @click="ChangeLanguage('it')">
					<v-list-item-title>Italiano</v-list-item-title>
				</v-list-item>
				<v-list-item @click="ChangeLanguage('en')">
					<v-list-item-title>English</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

	</v-toolbar-items>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="mb-5" style="min-height: 500px">
        <!-- If using vue-router -->
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
<IconSection></IconSection>
      <v-footer color="grey darken-3 px-5" dark>
		<v-container fluid>
        <v-row>
          <v-col cols="12" class="pa-3 text-center text-body-2">
            SGP Srl - via Sansovino, 8 - 20133 Milano (MI) - Italy - REA MI 1682239 -
            C.F./P.I./Reg.Imp. MI 03540510967 - Cap. Soc. € 30.000 i.v.
            <br />
            <div class="text-body-2">
              <router-link class="blue--text text--lighten-3 mr-5 NoDecoration" to="/Privacy">{{ $t("Privacy")}}</router-link>

              <router-link class="blue--text text--lighten-3 NoDecoration" to="/Terms-Conditions">{{ $t("TermsAndConditions") }} </router-link>
            </div>
          </v-col>
        </v-row>
		<!-- <v-row>
			<v-col>
				{{ $t("test") }}    {{  $i18n.locale }}
				<v-select :value="$i18n.locale" @change="ChangeLanguage" :items="[{text:'italiano', value:'it'}, { text: 'enghlish', value: 'en' }]"></v-select>
			</v-col>
		</v-row> -->
		</v-container>
      </v-footer>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CustomSnackBar from "./components/Shared/Common/CustomSnackBar.vue";
import SidebarMenu from "./components/Layout/SidebarMenu.vue";
import IconSection from "./views/Shop/IconSection";
export default {
	metaInfo() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
		};
	},
	components: {
		"custom-snack-bar": CustomSnackBar,
		SidebarMenu,
		IconSection
	},
	data() {
		return {
			VUE_APP_RUN_NUMBER: process.env.VUE_APP_RUN_NUMBER,
		};
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"selectedApplicationId",
			"isUserAuthenticated",
			"Basket",
			"BasketItemsCount",
			"MainProject"

		]),
		Logo() {
			if (this.MainProject?.ProjectId === 2) {
				return {
					LogoSrc: "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project02/Logo/StefanoGuindaniTextLogo.png",
					To: "/StefanoGuindani",
					Height: 60,
					Width: 130
				}
			}
			return {
				LogoSrc: "https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/Logo/BG4SDGs_logo_003.png",
				To: "/TimeToChange",
				Height: 60,
				Width: 60
			}
		},
	},
	created() {
		this.BasketVersionId = "V_20230724";
	},
	mounted() {
		this.$log.debug(
			"Who am I: " +
			(this.isUserAuthenticated ? this.UserProfile.Id : "not signed in")
		);

		this.$log.debug(this.$route.params);

		this.$store.watch(
			(state) => state.notifications.snack,
			(newValue) => {
				if (newValue.Text !== "") {
					this.$refs.snackBar.showNotification({
						Text: this.$store.state.notifications.snack.Text,
						Color: this.$store.state.notifications.snack.Color,
						Header: this.$store.state.notifications.snack.Header,
						Icon: this.$store.state.notifications.snack.Icon,
					});
				}
			}
		);

		// watch local storage, update basket whenever value update is detected
		window.addEventListener("storage", async ({ key, newValue }) => {
			this.$log.debug("Storage Watch: " + key);
			if (key === "ShopKey") {
				this.SetBasket(JSON.parse(newValue));
			}
		});

		// initialize basket with local storage
		const jsonStoredBasket = localStorage.getItem("ShopKey");
		const savedBasketVersionId = localStorage.getItem("BasketVersion");

		if (jsonStoredBasket && savedBasketVersionId && savedBasketVersionId === this.BasketVersionId) {
			this.$log.debug("Set basket from local storage");
			let basketObject = JSON.parse(jsonStoredBasket);
			if (basketObject == null) {
				basketObject = [];
			}
			this.SetBasket(basketObject);
		} else {
			this.$log.debug("Reset basket - old version detected");
			localStorage.removeItem("ShopKey");
			localStorage.removeItem("BasketVersion");
		}
	},
	watch: {
		// save basket content in local storage
		Basket: {
			deep: true,
			handler: function (val) {
				this.$log.debug("Basket mutation");
				this.$log.debug(val);

				localStorage.setItem("BasketVersion", this.BasketVersionId);
				localStorage.setItem("ShopKey", JSON.stringify(this.Basket));
			}
		}
	},
	methods: {
		...mapActions(["snackResetNotification", "snackError", "SetBasket"]),

		ToggleSidebar() {
			this.$refs.sidebar.ToggleDrawer();
		},

		CloseSidebar() {
			this.$refs.sidebar.showDrawer = false;
		},

		snackBarClosed() {
			this.snackResetNotification();
		},
		ChangeLanguage(locale) {
			this.$log.debug("change:" + locale);
			// this.SetCurrentLanugage(locale);

			if (this.$root.$i18n.locale !== locale) {
				this.$root.$i18n.locale = locale;
			}
		},
	}
};
</script>

<style lang="scss">
@import "@/assets/css/AppStyle.scss";

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
<i18n>
{
	"it":{
		"test": "value",
		"Privacy": "Informativa sulla privacy",
		"TermsAndConditions": "Termini e condizioni"
	},
	"en": {
		"test": "test en",
		"Privacy": "Privacy Information",
		"TermsAndConditions": "General Terms and Conditions "
	}
}
</i18n>
